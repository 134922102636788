<template>
  <div class="container" style="min-height:800px">
    <BreadCrumb :info="breadCrumb" v-if="!inner" />
    <div class="pContainer">
      <div>
        <ProductImages :product="product" v-if="!isLoading" />
        <FileContainer v-if="productType === 'printProduct' && $fnc.getWidth() > $store.state.mobileWidth"
        :title="product.title"
        :sizeName="sizeName"
        :fileGroups="fileGroups"
        :fileGroup="fileGroup"
        :fileGroupIndex="fileGroupIndex" />
      </div>
      <PrintProduct
        :product="product"
        :printTechnics="printTechnics"
        :productPrice="productPrice"
        v-if="productType === 'printProduct'"
        :rating="rating" :reviews="reviews"
        :usteEkle="usteEkle"
        :inner="inner"
        :innerPid="innerPid"
      />
      <NormalProduct2
        :innerPid="innerPid"
        :inner="inner"
      :product="product" :rating="rating" :reviews="reviews" v-if="productType === 'normal'" />
    </div>
    <div class="tabs" v-if="tabs && !inner">
      <div class="tabNavContainer">
        <div class="tabNav" style="min-width:600px">
          <button
          @click="selectTab(item)"
          v-for="item in tabs" :key="item.id" :class="{ 'tabActive': selectedTab.id === item.id }">{{ item.title }}</button>
        </div>
      </div>
      <div class="tabContent" v-if="!inner">
        <div v-for="item in tabs" :key="item.id">
          <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-if="item.type === 'comments'">
            <div v-if="reviews.length > 0">
              <div v-for="item in reviews" :key="item.id" class="comment-row">
                <div class="avatar">{{ item.user_name.split('')[0] }}</div>
                <div class="comment-bubble">
                  <div class="person">
                    <span>{{ item.user_name }}</span>
                    <span style="font-size:11px;text-align:right;color:#666">{{ $fnc.tarih(item.created_at) }}</span>
                  </div>
                  <star-rating :round-start-rating="false" v-model="item.rating" :star-size="14" :show-rating="false" :read-only="true"></star-rating>
                  <div v-if="item.comment" class="comment" v-html="$fnc.nl2br(item.comment)"></div>
                </div>
              </div>
            </div>
            <div v-else style="padding-top:20px;padding-bottom:10px">Şu anda bu ürüne hiç yorum yapılmamış</div>
          </div>
          <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else-if="item.type === 'taksit'">Taksit Seçenekleri</div>
          <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else-if="item.type === 'jsonPage'">
            <JsonPageNormal :page="item.content" />
          </div>
          <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else>
            <div class="ql-editor" v-html="item.content"></div>
            <ProductSpecs :specs="product.specs" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && !inner">
      <Collection v-if="product.relatedProducts" :info="product.relatedProducts" :listClass="'list5'" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FileContainer from '@/small-components/FileContainer.vue'
import ProductImages from '@/small-components/ProductImages.vue'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import PrintProduct from '@/components/PrintProduct.vue'
import NormalProduct2 from '@/components/NormalProduct2.vue'
import ProductSpecs from '@/small-components/ProductSpecs.vue'
import Collection from '@/home-components/Collection.vue'
import JsonPageNormal from '@/components/JsonPageNormal.vue'

export default {
  components: { FileContainer, ProductImages, BreadCrumb, PrintProduct, NormalProduct2, ProductSpecs, Collection, JsonPageNormal },
  props: ['inner', 'innerPid'],
  data: function () {
    return {
      pid: this.$route.params.pid,
      isLoading: true,
      isSaving: false,
      productType: '',
      product: '',
      variants: [],
      variantGroup: null,
      productPrice: 0,
      printTechnics: null,
      usteEkle: 0,
      selectedAdet: 0,
      fileGroups: [],
      fileGroup: [],
      sizeName: '',
      fileGroupIndex: 0,
      adets: null,
      newAdets: null,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: null,
      price: 0,
      totalPrice: 0,
      breadCrumb: [],
      rating: [],
      reviews: [],
      tabs: null,
      selectedTab: {
        id: 1,
        title: ''
      }
    }
  },
  beforeMount () {
    this.itemDetails()
  },
  watch: {
    $route (to, from) {
      this.isLoading = true
      this.product.relatedProducts = null
      const hash = to.hash
      const fromHash = from.hash
      if ((!hash) && (!fromHash)) {
        this.pid = this.$route.params.pid
        this.itemDetails()
      }
    }
  },
  methods: {
    selectTab: function (item) {
      this.selectedTab = item
    },
    sp: function (images) {
      this.product.images = images
    },
    setFileGroups: function (printSize, index) {
      this.sizeName = printSize.sizeName
      this.fileGroups = printSize.files
      this.fileGroup = printSize.files[0]
      this.fileGroupIndex = index
    },
    itemDetails: async function () {
      // console.clear()
      this.isLoading = true
      const payload = {
        pid: this.pid ?? this.innerPid,
        user: this.$store.state.user,
        inner: this.inner,
        selectedUser: this.$parent.selectedUser
      }
      await axios.post('api/template.php', payload).then(response => {
        if (response.data.error === 'none') {
          this.productType = response.data.productType
          this.usteEkle = response.data.usteEkle
          this.breadCrumb = response.data.bc
          this.rating = response.data.rating
          this.reviews = response.data.reviews
          this.tabs = response.data.tabs
          if (this.productType === 'printProduct') {
            const pts = (response.data.printTechnics)
            this.printTechnics = pts
            this.productPrice = pts[0].product.price
            this.product = response.data.product
            this.isLoading = false
          } else {
            this.product = response.data.product
            this.isLoading = false
            // fuck this
          }
        } else {
          // alert(response.data.error)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
// comment row
.person {
  display: grid;
  grid-template-columns: auto 90px;
  font-weight: bold;
  align-items: center;
}
.comment-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  align-items: flex-start;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ecebf3;
  margin-right: 10px;
  display: grid;
  place-items: center;
  font-weight: bold;
  color:#727272;
}
// comment bubble
.comment-bubble {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 0px;
}
.comment {
  padding: 6px;
}
.tabs {
  border:2px solid var(--color2);
  border-radius: 8px;
  padding: 10px;
  margin-top: 70px;
  margin-bottom: 20px;
  background:#fff;
  .tabNavContainer {
    width: 100%;
    overflow-y: auto;
  }
  .tabNav {
    display: flex;
    gap: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #cecece;
    button{
      border:none;
      background:none;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 6px;
      font-size: 14px;
    }
    .tabActive, button:hover {
      background: var(--color2);
      color: white;
    }
  }
}
.hidden {
  display:none;
}
.visible {
  display:block;
}
.pContainer {
  display:grid;
  grid-template-columns: 440px 5fr;
  gap: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width:768px) {
  .pContainer {
    grid-template-columns: 1fr;
    gap:12px;
  }
}
</style>
