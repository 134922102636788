import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Products from '../views/Products.vue'
import Product from '../views/Product.vue'
import Barcode from '../views/ProductBarcode.vue'
import Page from '../views/Page.vue'
import Contacts from '../views/Contacts.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import Cart from '../views/Cart.vue'
import CompleteOrder from '../views/CompleteOrder.vue'
import MyAccount from '../views/MyAccount.vue'
import Thanks from '../views/Thanks.vue'
import ErrorPage from '../views/ErrorPage.vue'
import SelectCustomer from '../views/SelectCustomer.vue'
// import NotFound from '../views/ErrorPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  }, {
    path: '/c/:top/:id',
    id: 0,
    t: '',
    brands: '',
    filters: '',
    name: 'Products',
    component: Products,
    props: true
  }, {
    path: '/search/:search',
    search: '',
    name: 'Search',
    component: Search,
    props: true
  }, {
    path: '/p/:pid',
    name: 'Product',
    component: Product,
    props: true
  }, {
    path: '/barkod',
    name: 'Barcode',
    component: Barcode,
    props: true
  }, {
    path: '/pg/:parent/:id',
    name: 'Page',
    component: Page,
    props: true
  }, {
    path: '/os/:id',
    name: 'PageOs',
    component: Page,
    props: true
  }, {
    path: '/iletisim',
    name: 'Contacts',
    component: Contacts,
    props: true
  }, {
    path: '/tesekkurler',
    name: 'Thanks',
    component: Thanks,
    props: true
  }, {
    path: '/hata',
    name: 'ErrorPage',
    component: ErrorPage,
    props: true
  }, {
    path: '/hesabim',
    name: 'MyAccount',
    component: MyAccount,
    props: true,
    meta: {
      title: 'Hesabım'
    },
    children: [
      {
        path: 'siparislerim',
        name: 'MyAccountOrders',
        props: true,
        meta: {
          title: 'Siparişlerim'
        }
      }, {
        path: 'uyelik-bilgilerim',
        name: 'MyAccountProfile',
        props: true,
        meta: {
          title: 'Üyelik Bilgilerim'
        }
      }, {
        path: 'urun-yorumlari',
        name: 'MyAccountReviews',
        props: true,
        meta: {
          title: 'Ürün Yorumları'
        }
      }, {
        path: 'adreslerim',
        name: 'MyAccountAddresses',
        props: true,
        meta: {
          title: 'Adres Bilgilerim'
        }
      }, {
        path: 'kuponlarim',
        name: 'MyAccountCoupons',
        props: true,
        meta: {
          title: 'Kuponlarım'
        }
      }, {
        path: 'bildirim-tercihlerim',
        name: 'MyAccountPermissions',
        props: true,
        meta: {
          title: 'Bildirim Tercihlerim'
        }
      }, {
        path: 'sifre-ve-guvenlik',
        name: 'MyAccountPassword',
        props: true,
        meta: {
          title: 'Şifrenizi Değiştirin'
        }
      }
    ]
  }, {
    path: '/giris-yap',
    name: 'Login',
    component: Login
  }, {
    path: '/uye-ol',
    name: 'Register',
    component: Login
  }, {
    path: '/sifremi-unuttum',
    name: 'ForgetPassword',
    component: Login
  }, {
    path: '/sepetim',
    name: 'Cart',
    component: Cart
  }, {
    path: '/siparisi-tamamla',
    name: 'CompleteOrder',
    component: CompleteOrder
  }, {
    path: '/customer/selectCustomer',
    name: 'SelectCustomer',
    component: SelectCustomer
  }, {
    path: '/:catchAll(.*)',
    // path: '/:lang/404.html',
    name: '404',
    component: ErrorPage
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

export default router
