<template>
  <router-link :to="{ name: 'Product', params: {pid: p.p.urunlerId + '-' + $fnc.seo(p.p.urunlerAdi) } } " class="product" v-if="p">
    <div v-if="p.badge" style="padding:10px;position:absolute;z-index:2">
      <div :style="'width:54px;height:54px;background:url(' + fileCdn + p.badge +');background-size:contain;'"></div>
    </div>
    <div class="imageContainer">
      <div v-if="p.images.length === 0"></div>
      <div v-else-if="p.images.length > 1">
        <div v-if="hoverSlide" style="position:relative;">
          <img loading="lazy" :alt="p.p.urunlerAdi" :src="'https://panel.graficopy.com.tr/promosyonb/media/250_' + p.images[index].source">
        </div>
        <div style="width:230px;display:flex;position:absolute;height:240px;margin-top:-240px;" v-if="hoverSlide">
          <div @mouseover="selectSlide(idx)" v-for="(i, idx) in p.images" :key="idx" style="width:100%"><i class="fa fa-square"></i></div>
        </div>
        <splide :options="options" v-if="!hoverSlide" class="prSplide">
          <splide-slide v-for="image in p.images" :key="image.source" class="sss">
            <img width="200px" :alt="p.p.urunlerAdi" :src="'https://panel.graficopy.com.tr/promosyonb/media/250_' + image.source">
          </splide-slide>
        </splide>
      </div>
      <div v-else>
        <img loading="lazy" style="user-drag: none;" :alt="p.p.urunlerAdi" :src="'https://panel.graficopy.com.tr/promosyonb/media/250_' + p.images[0].source">
      </div>
    </div>
    <p class="title" v-html="p.p.urunlerAdi"></p>
    <div v-if="p.rating" style="display:grid; place-items:center">
      <star-rating :title="p.rating.average + ' / 5 (' + p.rating.rateCount + ' değerlendirme)' " v-if="p.rating.average > 0" :round-start-rating="false" v-model="p.rating.average" :star-size="14" :show-rating="false" :read-only="true"></star-rating>
    </div>
    <p v-if="p.price">
      <span v-if="$store.state.priceWithVat">
        {{ parseFloat(p.price * (1 + (p.p.urunlerKdv / 100))).toFixed(2) }}₺
      </span>
      <span v-else>
        {{ parseFloat(p.price).toFixed(2) }}₺
      </span>
    </p>
  </router-link>
</template>

<script>
export default {
  props: ['p', 'prices'],
  methods: {
    selectSlide: function (idx) {
      this.index = idx
    }
  },
  data () {
    return {
      hoverSlide: false,
      notIpod: false,
      index: 0,
      fileCdn: this.$store.state.fileCdn,
      options: {
        rewind: true,
        perpage: 1,
        autoWidth: false,
        autoHeight: true,
        arrows: false,
        pagination: true,
        lazyLoad: false,
        autoplay: false,
        type: 'loop',
        keyboard: false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.product {
  cursor: pointer;
  color: #777;
  width: 100%;
  text-align: center;
  border: 1px solid #d3d3d3;
  background:#fff;
  padding-bottom:12px;
  border-radius: 8px;
  overflow: hidden;
  .title {
    font-weight: normal;
    font-size: 13px;
    width: 90%;
    margin: auto;
    color: #666;
  }
  p {
    font-weight: bold;
    color: #555;
  }
  img {
    width: 100%;
  }
}
.product:hover {
  transition: all 0.1s;
  color: var(--color1);
  box-shadow: 0px 0px 6px 0px #acacac;
}
.prSplide {
  display:grid;
  .sss {
    width: 200px;
  }
}
@media screen and (max-width: 360px) {
  .prSplide {
    width:280px; display:flex;height:280px;
    .sss {
      width: 200px;
    }
  }
}
@media screen and (min-width: 360px) and (max-width: 480px) {
  .product {
    border:none;
  }
  .product:hover {
    color: var(--color1);
    box-shadow: none;
  }
  .imageContainer {
    min-height:190px;
  }
}
</style>
