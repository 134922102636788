<template>
  <div class="mt">
    <div v-for="item in info" :key="item.id">
      <Flex v-if="item.component === 'flex'" :info="item" />
      <DesktopBlock v-if="item.component === 'desktopBlock' && $fnc.getWidth() > 768" :info="item" />
      <DesktopBlock v-if="item.component === 'mobileBlock' && $fnc.getWidth() <= 768" :info="item" />
      <Grid v-if="item.component === 'grid'" :info="item" />
      <Collection class="container" v-if="item.component === 'collection'" :info="item" :listClass="'list5'" />
      <Trendyol class="container" v-if="item.component === 'trendyol'" :info="item" :listClass="'list5'" />
    </div>
  </div>
</template>

<script>
import Flex from '../home-components/Flex.vue'
import DesktopBlock from '../home-components/DesktopBlock.vue'
import Grid from '../home-components/Grid.vue'
import Collection from '../home-components/Collection.vue'
import Trendyol from '../home-components/Trendyol.vue'

export default {
  components: {
    Flex,
    Grid,
    Collection,
    Trendyol,
    DesktopBlock
  },
  props: ['info'],
  data () {
    return {
      home: 'yes Mofos so'
    }
  }
}
</script>
