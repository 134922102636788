<template>
  <div>
    <div v-if="$store.state.mobileWidth > $fnc.getWidth()">
      <MobileHeader :info="info" />
    </div>
    <div v-else>
      <div v-if="info.companyInfo" style="background:#fff;">
        <div v-if="promo" class="promo" :style="'background:url(' + fileCdn + promo.image + ') no-repeat center;background-color: ' + promo.color + ';'">
          <router-link v-if="promo.url" :to="promo.url" style="width:100%;height:100%;" :title="promo.title"></router-link>
        </div>
        <header @mouseover="hideMenu" class="container">
          <div class="row1">
            <div v-for="item in info.topUrls" :key="item.id">
              <router-link v-if="item.route" :to="{ name:item.route.name, params: { id: item.route.id } }" :class="item.class"><i :class="item.icon"></i><span>{{ item.title }}</span></router-link>
              <router-link v-else :to="item.url" :class="item.class"><i :class="item.icon"></i><span>{{ item.title }}</span></router-link>
            </div>
            <div v-if="$store.state.user.email === 'personel@graficopy.com.tr'">
              <router-link :to="{ name: 'SelectCustomer' }" style="color:red">
                <i class="fa fa-file-alt"></i>
                <span>Yeni Sipariş</span>
              </router-link>
            </div>
          </div>
          <div class="row2">
            <router-link :to="'/'" class="logo" title="Anasayfa"></router-link>
            <div class="searchContainer">
              <div class="searchBox">
                <input @keydown.enter="makeSearch" type="text" v-model.trim="searchField" placeholder="Arayın, bulun">
                <button @click="makeSearch">Ara</button>
              </div>
            </div>
            <div class="cartZone">
              <button v-if="!$store.state.user.userId" @click="goPath('Login')" style="width:calc(100% - 114px);"><i class="far fa-user"></i> Üye Ol / Giriş Yap</button>
              <button v-else @click="goPath('MyAccountOrders')" style="width:calc(100% - 114px);">
                <i class="fa fa-user"></i>
                <div>
                  <div>Hesabım</div>
                  <div style="font-size:10px;width:90px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                    {{ $store.state.user.userName }}
                  </div>
                </div>
              </button>
              <button @click="goPath('Cart')" style="width:114px;margin-left:10px" class="sepet">
                <i class="fa fa-shopping-cart"></i>
                <span v-if="$store.state.cart" class="cartCount">{{ $store.state.cart }}</span>
                Sepetim
              </button>
            </div>
          </div>
          <div></div>
        </header>
        <nav @click="hideMenu" v-if="1024 < $fnc.getWidth()">
          <ul>
            <li @mouseover="setMenu(idx, item.subs.length)" v-for="(item, idx) in info.menu" :key="item.id">
              <router-link :class="{ 'activeMenu': openMenuId === idx}" v-if="item.brandId && item.brandId !== '0'"
                :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }, query: { brands:item.brandId } }">{{ item.title }} <i v-if="item.subs.length > 0" class="fa fa-angle-down"></i>
              </router-link>
              <router-link :class="{ 'activeMenu': openMenuId === idx}" v-else
                :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }} <i v-if="item.subs.length > 0" class="fa fa-angle-down"></i>
              </router-link>
            </li>
          </ul>
        </nav>
        <transition name="fade" style="z-index:2">
          <div v-if="subMenu">
            <section>
              <div class="container">
                <div class="subMenu">
                  <div class="parent">
                    <div :class="'divs' + item.sutun" v-for="(item) in menu" :key="item.id">
                      <ul class="subs" v-for="sItem in item.subs" :key="sItem.id">
                        <li>
                          <router-link @click.native="hideMenu"
                            :to="{ name: 'Products', params: { id: sItem.id + '-' + $fnc.seo(sItem.title), top: item.parent_id }}">{{ sItem.title }}
                          </router-link>
                          <ul v-for="sItem2 in sItem.subs" :key="sItem2.id">
                            <li>
                              <router-link v-if="!sItem2.url" @click.native="hideMenu"
                                :to="{ name: 'Products', params: { id: sItem2.id + '-' + $fnc.seo(sItem2.title), top: item.parent_id }}">{{ sItem2.title }}
                              </router-link>
                              <router-link v-else @click.native="hideMenu" :to="sItem2.url">
                              {{ sItem2.title }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div v-if="subMenu">
              <div @mouseover="hideMenu" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px'"></div>
            </div>
          </div>
        </transition>
      </div>
      <div v-else style="min-height:160px"></div>
    </div>
  </div>
</template>

<script>
import MobileHeader from '@/components/MobileHeader'

export default {
  components: { MobileHeader },
  props: ['info'],
  data () {
    return {
      subMenu: false,
      openMenuId: '',
      menu: this.info.menu[0].subs,
      promo: this.$store.state.info.topPromoDesktop,
      fileCdn: this.$store.state.fileCdn,
      hb: false,
      searchField: ''
    }
  },
  mounted () {
    if (this.$route.params.search) {
      this.searchField = this.$route.params.search
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart
    }
  },
  methods: {
    goPath: function (path) {
      this.$router.push({ name: path })
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    },
    setMenu: function (mId, c) {
      if (c > 0) {
        this.openMenuId = mId
        this.menu = this.info.menu[mId].subs
        this.showMenu()
      } else {
        this.hideMenu()
      }
    },
    showMenu: function () {
      setTimeout(() => {
        this.subMenu = true
      }, 0)
    },
    hideMenu: function () {
      this.openMenuId = ''
      this.subMenu = false
    },
    getCart: function () {
      this.$store.dispatch('setCart', 10)
    }
  }
}
</script>

<style scoped lang="scss">
.promo {
  height:50px;
  font-family:arial;font-weight:bold; color:#fff; text-align:center;font-size:30px;display:grid;place-items:center;
  background-size: contain !important;
}

header {
  display: grid;
  grid-template-rows: 40px auto 30px;
  .row1 {
    font-size:13px;
    line-height: 30px;
    display: flex;
    justify-content: flex-end;
    place-items: center;
    div {
      height: 24px;
      border-right: 1px solid #e6e6e6;
      margin-top: 2px;
      a {
        height: 100%;
        line-height: 100%;
        display: flex;
        place-items: center;
        padding-left: 18px;
        padding-right: 18px;
        font-family: var(--font2);
        color: #888;
        font-weight: bold;
        i {
          margin-right: 5px;
        }
      }
      .prime {
        background:var(--color1);
        color: white;
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .prime:hover {
        background:orangered;
        color: white;
        span {
          text-decoration: none;
        }
      }
      a:hover {
        span {
          text-decoration: underline;
        }
        color: var(--color1);
      }
    }
    div:last-child {
      border: none;
      a {
        padding-right: 0px;
      }
    }
  }
  .row2 {
    display:grid;
    gap: 20px;
    grid-template-columns: 152px auto 294px;
    .cartZone {
      display:flex;
      justify-content: flex-end;
      align-items: center;
      button {
        padding-left:13px;
        padding-right:13px;
        border:none;
        border-radius: 5px;
        height: 42px;
        border:3px solid #ccc;
        background:#fff;
        font-family: var(--font1);
        color: var(--gri2);
        display: flex;
        place-items: center;
        font-weight: bold;
        justify-content: center;
        i {
          font-size: 20px;
          color: var(--gri2);
          margin-right: 8px;
        }
      }
      .sepet {
        background:var(--color2);
        color: #fff;
        border-color: var(--color2);
        transition: all 0.1s ease-in;
        .cartCount {
          position:absolute;
          display:block;
          border: 2px solid var(--color2);
          color: var(--color2);
          background:var(--color1);
          color: #fff;
          width:20px;
          height:20px;
          line-height: 18px;
          border-radius:10px;
          font-size: 10px;
          font-family:var(--font2);
          margin-left:-41px;
          margin-top:-20px;
        }
        i {
          color: #fff;
        }
      }
      .sepet:hover {
        filter: brightness(var(--bri));
      }
    }
  }
}
.searchContainer {
  display:grid;place-items:center;
  font-family: var(--font2);
  .searchBox {
    width:100%;
    border:2px solid var(--gri2);
    border-radius:5px;
    overflow:hidden;
    height:42px;
    background: var(--gri2);
    input {
      padding:5px;
      padding-left:40px;
      border:none;
      width: calc(100% - 60px);
      background: url(../assets/search.svg) left no-repeat;
      background-size: 20px 20px;
      background-position: 12px;
      background-color:#fff !important;
      height:100%;
    }
    button {
      background:var(--gri2);
      color:#fff;
      width:60px;
      height:100%;
      border:none;
      transition: 0.1s all ease-in;
    }
    button:hover {
      filter: brightness(1.2);
    }
  }
}
.subMenu {
  font-family: var(--font2);
  background:#fff;
  position:absolute;
  width:1200px;
  z-index:3;
  padding:20px;
  margin-top: 0px;
}
.parent {
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
}
ul.subs {
  list-style:none;
  li{
    margin-bottom: 20px;
    a {
      font-size: 14px;
      font-weight: bold;
      color:#666;
    }
    a:hover, .active {
      color:var(--color1);
    }
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-top: 2px;
        padding-bottom: 2px;
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.overlay {
  background:rgba(0,0,0,0.4);width:100%;height:1200px;position:absolute;z-index:2;
}
.fade-enter-active, .fade-leave-active {
  z-index: 4;
  position: absolute;
  width:100%;
  transition: opacity .1s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  z-index: 4;
  position: absolute;
  width:100%
}
.logo {
  margin:auto;
  width: 100%;
  height: 50px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.png') left no-repeat;
  background-size: contain;
}
.logo2 {
  margin:auto;
  width: 100%;
  height: 60px;
  display: grid;
  align-items:center;
  mask: url('../assets/logo.png') left no-repeat;
  // background: url(https://www.bidolubaski.com/themes/custom/bdbv2/logo.svg) left no-repeat;
  mask-size: contain;
  background-color: #3C3C3B;
  // background-color: var(--color1);
}
nav {
  background:url(../assets/stripe.png) repeat-x;
  background-size:450px 3px;
  height: 50px;
  background-color:#f5f5f5;
  background-color:#fff;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.05);
  user-select: none;
  ul {
    margin: auto;
    list-style: none;
    display: flex;
    justify-content: center;
    li {
      ul {
        width:200px;
      }
    }
  }
  a {
    color: #555;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    display: inline-block;
    height: 45px;
    line-height: 45px;
    margin-top: 4px;
    border-right: 1px solid #e7e7e7;
    transition: all 0.2s;
  }
  a:first-child {
    border-left: 1px solid #e7e7e7;
  }
  a:hover, .activeMenu {
    background:#fff;
    color: var(--color1);
  }
}

.sag {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.cart {
  border:none;
  mask: url('../assets/icons/cart.svg') no-repeat center;
  mask-size: cover;
  background-color: black;
  width: 20px;
  height: 20px;
}
.search {
  border:none;
  mask: url('../assets/icons/mag.svg') no-repeat center;
  mask-size: cover;
  background-color: black;
  width: 17px;
  height: 17px;
}
@media screen and (max-width: 768px) {
  nav {
    display:none
  }
}
</style>
