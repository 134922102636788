<template>
  <div>
    <Header :info="info" />
    <div class="container">
      <BreadCrumb :info="[{ id:0, title: 'İletişim Bilgilerimiz' }]" />
      <div class="mb catalog">
        <div style="min-height:340px">
          <gmaps-map :options="options">
            <gmaps-marker :position="{ lat: options.center.lat, lng: options.center.lng }" />
          </gmaps-map>
        </div>
        <div>
          <h3>{{ company.PAGE_UNVAN }}</h3>
          <ul class="contact">
            <li><i class="fa fa-map-marker"></i> {{ company.PAGE_ADRES }}</li>
            <li><i class="fa fa-phone-alt"></i> {{ company.PAGE_PHONE }}</li>
            <li><i class="fa fa-envelope"></i> {{ company.PAGE_EPOSTA }}</li>
          </ul>
        </div>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.catalog {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh - 500px);
}
.contact {
  list-style: none;
  li {
    display: grid;
    grid-template-columns: 28px auto;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    i {
      font-size:12px;
      text-align: center;
      color: #5c5c5c;
    }
  }
}
</style>

<script>
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
export default {
  components: { BreadCrumb, gmapsMap, gmapsMarker },
  props: ['info'],
  data () {
    return {
      company: this.info.companyInfo,
      options: {
        center: {
          lat: parseFloat(this.info.companyInfo.PAGE_KOOR.split(',')[0]),
          lng: parseFloat(this.info.companyInfo.PAGE_KOOR.split(',')[1])
        },
        zoom: 15,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: true,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false
      }
    }
  }
}
</script>
