var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.totalProducts > 0)?_c('div',{staticStyle:{"display":"grid","place-items":"center","margin-top":"30px"}},[_c('ul',{staticClass:"pager"},[(_vm.page > 1)?_c('li',[((_vm.page - 1) === 1)?_c('router-link',{attrs:{"title":'Önceki Sayfa',"rel":"prev","to":{ name: 'Search', params: {search: _vm.$route.params.search }, query: {
        brands: _vm.brands,
        comp_brands: _vm.comp_brands,
        comp_models: _vm.comp_models,
        campaignId: _vm.$route.query.campaignId,
        filters: _vm.filters
      }}}},[_c('i',{staticClass:"fa fa-angle-double-left"})]):_c('router-link',{attrs:{"title":'Önceki Sayfa',"rel":"prev","to":{ name: 'Search', params: {search: _vm.$route.params.search }, query: {
        brands: _vm.brands,
        filters: _vm.filters,
        comp_brands: _vm.comp_brands,
        comp_models: _vm.comp_models,
        campaignId: _vm.$route.query.campaignId,
        p: (_vm.page - 1)
      }}}},[_c('i',{staticClass:"fa fa-angle-double-left"})])],1):_c('li',[_vm._m(0)]),_vm._l((_vm.pageRange),function(i,idx){return _c('li',{key:idx},[(i === 1)?_c('router-link',{class:{ activePage: _vm.page === i },attrs:{"to":{ name: 'Search', params: {search: _vm.$route.params.search }, query: {
        brands: _vm.brands,
        comp_brands: _vm.comp_brands,
        comp_models: _vm.comp_models,
        campaignId: _vm.$route.query.campaignId,
        filters: _vm.filters
      }}}},[_vm._v(_vm._s(i))]):_c('router-link',{class:{ activePage: _vm.page === i },attrs:{"rel":((_vm.page + 1) === (i)) ? 'next' : false,"to":{ name: 'Search', params: {search: _vm.$route.params.search }, query: {
        brands: _vm.brands,
        filters: _vm.filters,
        comp_brands: _vm.comp_brands,
        comp_models: _vm.comp_models,
        campaignId: _vm.$route.query.campaignId,
        p: i
      }}}},[_vm._v(_vm._s(i))])],1)}),(_vm.page < Math.ceil(_vm.totalProducts / _vm.pageSize))?_c('li',[_c('router-link',{attrs:{"title":'Sonraki Sayfa',"rel":"next","to":{ name: 'Search',params: {search: _vm.$route.params.search }, query: {
        brands: _vm.brands,
        filters: _vm.filters,
        comp_brands: _vm.comp_brands,
        comp_models: _vm.comp_models,
        campaignId: _vm.$route.query.campaignId,
        p: (_vm.page + 1)
      }}}},[_c('i',{staticClass:"fa fa-angle-double-right"})])],1):_c('li',[_vm._m(1)])],2),_c('div',{staticStyle:{"font-size":"14px","margin-top":"8px"}},[_vm._v(" "+_vm._s(Math.ceil(_vm.totalProducts / _vm.pageSize))+" sayfada toplam "+_vm._s(_vm.totalProducts)+" sonuç var ")])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"passive"},[_c('i',{staticClass:"fa fa-angle-double-left"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"passive"},[_c('i',{staticClass:"fa fa-angle-double-right"})])
}]

export { render, staticRenderFns }