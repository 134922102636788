<template>
  <div>
    <h1 class="productTitle" v-html="product.title"> </h1>
    <div v-if="rating.average > 0" style="display:flex; justify-:left;align-items:center; margin-bottom:12px;margin-top:0px">
      <div style="font-weight:bold;height:24px;margin-right:12px;">
        {{ rating.average }}
      </div>
      <star-rating v-if="rating.average > 0" :round-start-rating="false" v-model="rating.average" :star-size="16" :show-rating="false" :read-only="true"></star-rating>
      <b style="font-size:11px;margin-left:13px;color:#666">{{ rating.rateCount }} değerlendirme</b>
    </div>
    <div v-if="!isLoading" class="productDetail">
      <div>
        <div>
          <div class="selections" v-if="variants.length > 0">
            <div class="title"><i class="fa fa-caret-down"></i>Ürün Seçin</div>
            <div class="buttonset" style="display:none">
              <select @change="selectVariant()" style="width:100%; height:40px; border-radius:4px;padding-left:10px; border:2px solid #40bbf4">
                <option v-for="item in variants" :key="item.id"> {{ item.name }}</option>
              </select>
            </div>
            <div class="buttonset" :class="{ flewWrap: variants.length > 5}">
              <button :id="'var' + item.id" @click="selectVariant(item.id)" v-for="(item) in variants" :key="item.id"
              :class="{ 'activeButton': item.id === selectedVariant, 'notInStock': item.stock < 1 }">
                {{ item.name }}
              </button>
            </div>
          </div>
          <BuyTogetherPrint :p="product.bundles"/>
          <div class="selections" v-if="printTechnics.length > 0">
            <div class="title"><i class="fa fa-print"></i> Baskı Tekniği</div>
            <div class="buttonset">
              <button @click="selectTechnic(item, idx)" v-for="(item, idx) in printTechnics" :key="item.technicId" :class="{ 'activeButton': item.technicId === selectedTechnic.technicId }">
                <p class="icon" v-if="item.icon"
                  :style="'background:url(https://panel.graficopy.com.tr/promosyonb/files/' + parseIcon(item.icon) + ') no-repeat center'">
                </p>
                {{ item.technicName }}
              </button>
            </div>
          </div>
          <div class="selections" v-for="item in selectedTechnic.printOptions" :key="item.id">
            <div class="title">
              {{ item.title }}
              <div class="infoQ" v-if="item.description" v-tooltip="{
                content: item.description,
                trigger: 'hover click',
                autoHide: true,
                delay: { show: 10, hide: 1500 }
              }">?</div>
            </div>
            <div class="buttonset" :class="{ flewWrap: item.options.length > 5}">
              <button
              :id="item.tip + '-O-' + op.id"
              v-for="op in item.options"
              @click="selectOption(op, item)"
              :key="op.id"
              :class="item.tip + ' printOptionBtn'">
                <p class="icon" v-if="op.icon"
                :style="'background:url(https://panel.graficopy.com.tr/promosyonb/files/' + parseIcon(op.icon) + ') no-repeat center'">
                </p>
                <p>{{ op.name }}</p>
              </button>
            </div>
            <div v-for="op in item.options" :key="op.id">
              <div class="optDesc" :id="item.tip + '-DESC-' + op.id" :class="item.tip + 'OD'" v-if="op.description">
                {{ op.description }}
              </div>
            </div>
          </div>
          <div style="font-size: 12px;">
            Baskılı ürünlerde seçtiğiniz adet seçeneği için sadece bir adet tasarım yükleyebilirsiniz. Birden fazla tasarımınız var ise ayrı ayrı adet seçmeniz ve sepete atmanız gerekmektedir.
          </div>
          <div class="adets" v-if="customQty">
            <h2 style="text-align: center;font-weight: bold;">{{ termin }} İş Gününde Kargoda</h2>
            <div @click="selectAdet(item.adet)" class="adetRow" v-for="(item,idx) in newAdets" :key="idx">
              <div class="adetci">
                <span class="rakam">{{ item.adet }}</span>
                <span class="adet">Adet</span>
              </div>
              <div class="rakamci" :class="{ 'activeButton': item.adet === selectedAdet }">
                <div class="kdvHaric">{{ (item.price).toFixed(2) }} TL <span> + KDV</span></div>
                <div class="kdvDahil">{{ (item.price * kdv).toFixed(2) }} TL (KDV Dahil)</div>
              </div>
            </div>
          </div>
          <div v-else class="qtyRow" style="margin-top: 20px; margin-bottom:20px;">
            <span>Miktar</span>
            <div class="qtySelector">
              <button @click="decrease"><i class="fa fa-minus"></i></button>
              <input @change="selectAdet(2)" v-model.number="selectedAdet" min="1" type="tel">
              <button @click="increase"><i class="fa fa-plus"></i></button>
            </div>
          </div>
        </div>
        <div style="font-size: 12px;margin-top: 10px;">
          Belirtilen kargo tarihleri, iş gününde (haftasonu ve resmi tatiller hariç), ekspres gönderim seçeneği için aynı gün saat 10:00'a kadar, diğer gönderim seçenekleri için ise aynı gün saat 14:00'a kadar tarafınızdan baskı onayı verilmesi halinde geçerli olacak tahmini tarihlerdir.
        </div>
      </div>
      <ProductPrice :totalPrice="totalPrice" :isSaving="isSaving" />
      <FileContainer v-if="$fnc.getWidth() < $store.state.mobileWidth" :title="product.title" :sizeName="$parent.sizeName" :fileGroups="$parent.fileGroups" :fileGroup="$parent.fileGroup" :fileGroupIndex="$parent.fileGroupIndex" />
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import axios from 'axios'
import ProductPrice from '@/small-components/ProductPrice.vue'
import FileContainer from '@/small-components/FileContainer.vue'
import BuyTogetherPrint from '@/small-components/BuyTogetherPrint.vue'

export default {
  props: ['product', 'printTechnics', 'productPrice', 'rating', 'usteEkle'],
  components: { ProductPrice, FileContainer, BuyTogetherPrint },
  data: function () {
    return {
      pid: this.$route.params.pid,
      isLoading: true,
      isSaving: false,
      productType: '',
      variants: [],
      variantGroup: null,
      selectedTechnic: null,
      selectedAdet: 0,
      fileGroups: [],
      fileGroup: [],
      sizeName: 'Test',
      fileGroupIndex: 0,
      adets: null,
      newAdets: null,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: 0,
      price: 0,
      totalPrice: 0,
      selectedVariant: 0,
      customQty: true,
      mediaCdn: this.$store.state.mediaCdn,
      selectedBundle: 0
    }
  },
  mounted () {
    this.itemDetails()
  },
  watch: {
    $route (to, from) {
      this.isLoading = true
    },
    newPid (to, from) {
      setTimeout(() => {
        this.itemDetails()
      }, 40)
    }
  },
  computed: {
    newPid: function () {
      return this.$parent.product.id
    }
  },
  filters: {
    parseIcon: function (value) {
      if (!value) {
        return ''
      }
      const d = JSON.parse(value)
      return (d.source)
    }
  },
  methods: {
    selectBundle: function (bundle) {
      this.selectedBundle = bundle
      this.bundlePrice = bundle.price
      this.bundleVat = bundle.vatRate
      this.calculate()
    },
    decrease: function () {
      if (this.selectedAdet > 1) {
        this.selectedAdet--
      }
    },
    increase: function () {
      if (this.selectedAdet < 5000) {
        this.selectedAdet++
      }
    },
    parseIcon: function (value) {
      if (!value) {
        return ''
      }
      const d = JSON.parse(value)
      return (d.source)
    },
    selectOption: function (op, item) {
      if (item.files) {
        const files = item.files.find(arr => arr.sizeId === op.id)
        this.$parent.fileGroups = files.files
        this.$parent.fileGroup = files.files[0]
        this.$parent.sizeName = op.name
      }
      const btns = document.querySelectorAll('.' + item.tip)
      btns.forEach(b => {
        b.classList.remove('activeButton')
      })
      const el = document.querySelector('#' + item.tip + '-O-' + op.id)
      if (el) {
        el.classList.add('activeButton')
      }
      const descs = document.querySelectorAll('.' + item.tip + 'OD')
      descs.forEach(b => {
        b.classList.add('hidden')
      })
      const el2 = document.querySelector('#' + item.tip + '-DESC-' + op.id)
      if (el2) {
        el2.classList.remove('hidden')
      }
      this.calculate()
    },
    addToCart: async function () {
      this.isSaving = true
      var selecteds = []
      const btns = document.querySelectorAll('.printOptionBtn')
      btns.forEach(b => {
        if (b.className.includes('activeButton')) {
          const tip = b.id.split('-O-')[0]
          const id = parseInt(b.id.split('-O-')[1])
          const ob = this.selectedTechnic.printOptions.find(arr => arr.tip === tip)
          const po = ob.options.find(arr2 => arr2.id === id)
          selecteds.push({ tip: tip, name: po.name, id: po.id, sel: 'id', tipName: ob.title2 })
        }
      })
      // selecteds.push({ tip: 'printColor', name: color.colorName, id: color.colorId, sel: 'colorId', tipName: 'Renk' })
      const payload = {
        pid: this.pid,
        bid: this.selectedVariant,
        qty: this.selectedAdet,
        productType: 'printProduct',
        printTechnic: this.selectedTechnic.technicId,
        selecteds: selecteds,
        bundle: this.selectedBundle,
        user: this.$store.state.user,
        unitPrice: this.totalPrice / (1 + (this.product.kdvOran / 100)),
        totalPrice: this.totalPrice,
        kdvOran: this.product.kdvOran,
        id: Math.floor(Math.random() * 1000000000),
        product: this.product,
        printTechnicName: this.selectedTechnic
      }
      if (this.$parent.inner) {
        console.log(payload)
        this.$parent.$parent.orderProducts.push(payload)
        this.$parent.$parent.selectedPid = false
        this.$parent.$parent.productSearch = ''
        setTimeout(() => {
          this.isSaving = false
        }, 100)
        return
      }
      await axios.post('/api/addToCart.php', payload).then(response => {
        setTimeout(() => {
          this.isSaving = false
          this.$store.dispatch('setCart', response.data.cartCount)
        }, 500)
      })
    },
    selectAdet: function (adet) {
      this.selectedAdet = adet
      this.calculate()
    },
    selectTechnic: function (technic, index) {
      this.selectedTechnic = technic
      setTimeout(() => {
        technic.printOptions.forEach(po => {
          this.selectOption(po.options[0], po)
        })
      }, 1)
      setTimeout(() => {
        this.selectAdet(this.selectedTechnic.adets[0].adet)
        this.calculate()
      }, 1)
    },
    calculate: function () {
      let total = 0
      const mods = []
      let productPrice = 0
      if (!this.variantPrice) {
        productPrice = this.productPrice
      } else {
        productPrice = this.variantPrice
      }
      const btns = document.querySelectorAll('.printOptionBtn')
      if (this.selectedBundle !== 0) {
        productPrice += this.selectedBundle.price
      }
      btns.forEach(b => {
        if (b.className.includes('activeButton')) {
          const tip = b.id.split('-O-')[0]
          const id = parseInt(b.id.split('-O-')[1])
          const ob = this.selectedTechnic.printOptions.find(arr => arr.tip === tip)
          const po = ob.options.find(arr2 => arr2.id === id)
          if (po.modifier) {
            mods.push(po.modifier)
          } else {
            total += parseFloat(po.price * 1)
          }
        }
      })
      const carpan = this.selectedTechnic.carpan
      const newAdets = []
      this.selectedTechnic.adets.forEach(a => {
        const adetPrice = ((total) * (a.adet / carpan))
        const baskiPrice = (a.price * (a.adet / carpan))
        let totalPrice = (adetPrice + baskiPrice)
        mods.forEach(m => {
          totalPrice = (totalPrice) * m
        })
        totalPrice += (productPrice * (a.adet / carpan))
        const sabitMasraf = this.selectedTechnic.technicPrice1 + this.selectedTechnic.technicPrice2
        totalPrice += (sabitMasraf)
        totalPrice = totalPrice * (1 + (this.usteEkle / 100))
        newAdets.push({ adet: a.adet, price: totalPrice })
      })
      this.newAdets = newAdets
      setTimeout(() => {
        if (this.selectedAdet) {
          const totalPrice = newAdets.find(arr => arr.adet === this.selectedAdet).price
          this.totalPrice = (totalPrice) * this.kdv
        }
      }, 10)
    },
    selectVariant: function (variantId) {
      this.variantPrice = this.variants.find(arr => arr.id === variantId).price
      const vvv = this.variants.find(arr => arr.id === variantId)
      if (vvv.stock <= 0) {
        const vElement = document.getElementById('var' + variantId)
        if (vElement) {
          vElement.blur()
        }
        return
      }
      this.selectedVariant = variantId
      if (vvv.images.length > 0) {
        this.$parent.sp(vvv.images)
      } else {
        this.$parent.product.images = this.product.fullImages
      }
      this.calculate()
    },
    selectPrintSize: function (files, sizeName) {
      this.fileGroups = files[0]
      this.fileGroup = files[0][0]
      this.$parent.sizeName = sizeName
      this.$parent.fileGroups = files[0].files
      this.$parent.fileGroup = files[0].files[0]
      this.$parent.fileGroupIndex = 0
    },
    itemDetails: function () {
      const pts = (this.printTechnics)
      this.selectTechnic(pts[0], 0)
      this.kdv = (this.selectedTechnic.kdvOran / 100) + 1
      this.termin = this.selectedTechnic.termin
      if (this.product.variants.length > 0) {
        this.variants = this.product.variants
        this.selectVariant(this.variants[0].id)
      } else {
        this.variants = []
      }
      const files = pts[0].printOptions[0].files
      const sizeName = pts[0].printOptions[0].options[0].name
      this.selectPrintSize(files, sizeName)
      this.isLoading = false
      const bundles = this.product.bundles
      if (bundles) {
        this.selectBundle(bundles.p[0])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.flewWrap {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}
.tip {
  background:#665;
  color: #fff;
  font-weight: bold;
}
.infoQ {
  width:16px !important;
  height:16px !important;
  display:grid;
  place-items:center;
  background:#7c7c7c;
  border-radius:50%;
  color:white;
  font-size:8px;
  font-family: var(--font3);
  cursor: pointer;
}
.hidden {
  display:none;
}
.optDesc {
  // yellow background info box
  background-color: #fff9d3;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400 !important;
  width: calc(100% - 10px) !important;
}
button {
  .icon {
    height:48px;
    width:50px;
    background-size: contain;
  }
}
.productDetail {
  display:grid;
  grid-template-columns:auto 200px;
  height: 100%;
  gap:20px
}
.productTitle {
  font-weight: bold;
  color:var(--black1);
  text-align: left;
  font-size:1.3rem;
  font-display: swap;
  padding-bottom: 10px;
  user-select: text;
}
@media screen and (max-width:768px) {
  .productDetail {
    grid-template-columns: 1fr;
  }
}
.selections {
  display:grid;
  grid-template-columns: 1fr;
  place-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  div {
    width: 100%;
    font-size: 11px;
    font-weight: bold;
  }
  .buttonset {
    display:flex;
    align-content: center;
    // gap:10px;
  }
  .title {
    align-items: center;
    color: #555;
    display:flex;
    gap:3px;
    height:24px;
    align-items:center;
    font-family: var(--font2);
  }
  .title i {
    display:none;
  }
}
.buttonset button {
  width: 100%;
  border:2px dashed #ccc;
  color: grey;
  background: #fff;
  border-radius: 4px;
  font-size: 11px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0px;
  margin-right:12px;
  outline: none;
  display: grid;
  gap: 2px;
  place-items: center;
  box-shadow:0px 4px 12px -8px rgba(0, 0, 0, 0.4) !important;
}
.buttonset button i {
  font-size: 26px;
  margin-top: 4px;
}
.buttonset button:hover {
  border: 2px solid rgb(136, 136, 136);
  color: black;
}
.buttonset button:focus, .buttonset button:active, .activeButton {
  border: 2px solid #40bbf4;
  color: black;
}
.adets {
  background:#fafafa;
  padding: 15px;
  font-size: 12px;
  margin-top: 10px;
  border-radius: 6px;
}
.adetRow {
  display: grid;
  grid-template-columns: 50px auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.adetci {
  display: grid;
  place-items: center;
  gap: 0px;
  font-size: 10px !important;
  font-weight: bold;
  margin:0px;
  padding:0px;
  cursor:pointer;
}
.rakamci {
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #d4d4d4;
  cursor: pointer;
  transition: all 0.3s;
}
.rakamci .kdvHaric {
  font-size: 20px !important;
  font-weight: bold;
  line-height:20px;
}
.rakamci .kdvDahil{
  font-size: 11px !important;
  font-weight: bold;
  line-height:11px;
}
.rakamci:hover {
  border: 2px solid #979797;
}
.rakam {
  font-weight: bold;
  font-size: 13px;
  line-height: 13px !important;
}
.adet {
  font-size: 11px;
  line-height: 11px !important;
}
.notInStock, .notInStock:hover, .notInStock:focus {
  border-color: #bebebe;
  color: #bebebe;
  text-decoration: line-through;
  cursor: not-allowed !important;
}
</style>
