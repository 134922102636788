<template>
  <div>
    <div>
      <div v-if="promo" class="promo" :style="'background:url(' + fileCdn + promo.image + ') no-repeat center;background-color: ' + promo.color + ';'">
        <router-link v-if="promo.url" :to="promo.url" style="width:100%;height:100%;" :title="promo.title"></router-link>
      </div>
      <header>
        <div>
          <button v-if="hasDrawer" @click="showBottomMenu" :disabled="overlay" title="Menü">
            <i class="fa fa-bars"></i>
          </button>
        </div>
        <div>
          <router-link :to="'/'" class="logo" title="Anasayfa"></router-link>
        </div>
        <div>
          <button @click="showSearch" :disabled="overlay" title="Ara">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </header>
    </div>
    <div class="sidenav" :class="{ 'sidenavOpen': subMenu }">
      <a @click="hideMenu" class="closebtn">×</a>
      <a href="#1" @click="showBottomMenu">Kategoriler</a>
      <a href="#1">Kategoriler</a>
      <a href="#1">Kategoriler</a>
    </div>
    <div v-if="searchModal" class="searchModal">
      <div class="input">
        <i class="fa fa-search"></i>
        <input @keypress.enter="makeSearch" id="mobileSearcher" type="text" v-model.trim="searchField" placeholder="Binlerce Ürün İçinde Aradığınızı Bulun">
        <button @click="makeSearch">Ara</button>
      </div>
    </div>
    <div v-if="searchModal">
      <div @mouseover="hideSearch" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px;'"></div>
    </div>
    <div v-if="subMenu">
      <div @mouseover="hideMenu" class="overlay" :style="'height:' + ($fnc.getHeight() - 200) + 'px;'"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.promo {
  height:40px;
  font-family:arial;font-weight:bold; color:#fff; text-align:center;font-size:30px;display:grid;place-items:center;
  background-size: cover !important;
}
.overlay {
  background:rgba(0,0,0,0.7);width:100%;height:100%;position:absolute;z-index:12; top:0;
  overscroll-behavior: contain;
}
.searchModal {
  height: 60px;
  width: (100vw);
  position: fixed;
  z-index: 120;
  top:56px;
  left: 0;
  transition: all 0.1s ease-in;
  .input {
    padding: 10px;
    width:calc(100% - 30px);
    margin-left:15px;
    border-radius: 8px;
    border: 2px solid var(--black2);
    background:#fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input:focus {
    box-shadow: unset;
  }
  i {
    width:24px;
  }
  button {
    width:40px;
  }
  input{
    width:100%;
    outline: none;
    border: none;
    background: none;
  }
}
.sidenav {
  overscroll-behavior: contain;
  height: 100%;
  width: 320px;
  position: fixed;
  z-index: 103;
  top: 0;
  left: -320px;
  background-color:#222529;
  overflow-x: hidden;
  transition: 0.3s ease-out;
  padding-top: 60px;
  font-family: var(--font2);
  a {
    overscroll-behavior: contain;
    padding: 8px 8px 8px 26px;
    margin-bottom: 8px;
    text-decoration: none;
    font-size: 22px;
    color: #c2c2c2;
    display: block;
    transition: 0.3s;
    outline:none;
  }
  a:hover {
    color: #f1f1f1;
  }
  .closebtn {
    overscroll-behavior: contain;
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 36px;
    margin-left: 50px;
  }
}
.sidenavOpen {
  left: 0;
}
.headerContainer {
  border-bottom: 1px solid #f1f1f1;
  // box-shadow: 0px 2px 10px rgba(0,0,0,0.15);
  z-index: 10;
  position: absolute;
  width: calc(100vw);
  background: #fff;
}
header {
  display: grid;
  grid-template-columns: 60px auto 60px;
  align-items: center;
  justify-items: center;
  padding-top: max(10px, env(safe-area-inset-top));
  padding-bottom: 10px;
  border-bottom: 1px solid #dadada;
  div {
    width: 100%;
    height: 100%;
    button {
      z-index:1;
      border:none;background:none;width:100%; height:100%;
      outline: none;
      i {
        font-size: 16px;
      }
    }
  }
}
.logo {
  margin:auto;
  width: 130px;
  height: 50px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.png') center no-repeat;
  // background: url('https://mcdn01.gittigidiyor.net/cdimg/anasayfa/nucleus/gglogo.svg') no-repeat center;
  // background: url('https://www.bidolubaski.com/themes/custom/bdbv2/logo.svg') center no-repeat;
  background-size: contain;
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false,
      overlay: false,
      searchModal: false,
      openMenuId: '',
      menu: this.info.menu[0].subs,
      promo: this.$store.state.info.topPromoMobile,
      fileCdn: this.$store.state.fileCdn,
      hasDrawer: true,
      searchField: ''
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart
    }
  },
  watch: {
    $route (to, from) {
      const h = (this.$route.hash)
      if (h === '#1') {
        // this.subMenu = true
      } else {
        // this.subMenu = false
      }
    }
  },
  methods: {
    showMenu: function () {
      this.overlay = true
      this.subMenu = true
      document.querySelector('body').style.overflow = 'hidden'
    },
    hideMenu: function () {
      this.overlay = false
      this.subMenu = false
      document.querySelector('body').style.overflow = 'auto'
    },
    showBottomMenu: function () {
      this.hideMenu()
      setTimeout(() => {
        this.$store.dispatch('setBottomMenu', !this.$store.bottomMenu)
      }, 10)
    },
    showSearch: function () {
      this.searchModal = true
      this.overlay = true
      document.querySelector('body').style.overflow = 'hidden'
      setTimeout(() => {
        document.querySelector('#mobileSearcher').focus()
      }, 100)
    },
    hideSearch: function () {
      this.searchModal = false
      this.overlay = false
      document.querySelector('body').style.overflow = 'auto'
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    },
    getCart: function () {
      this.$store.dispatch('setCart', 10)
    }
  }
}
</script>
