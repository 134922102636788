<template>
  <div>
    <div class="car">
      <div v-if="savingPassword" style="display:grid;place-items:center">
        <img src="@/assets/loading-pulse.svg">
      </div>
      <div v-else>
        <div v-if="passSuccess">Şifreniz başarıyla değişti</div>
        <form method="dialog" autocomplete="never" v-else>
          <p class="formError" v-if="passwordError">{{ passwordError }}</p>
          <div id="currentpass">
            <label>Mevcut Şifreniz</label>
            <input @keypress.enter="changePassword" v-model="currentPass" type="password" autocomplete="never" placeholder="Mevcut Şifreniz">
          </div>
          <div id="newpassword">
            <label>Yeni Şifreniz</label>
            <input @keypress.enter="changePassword" v-model="newPass" type="password" autocomplete="never" placeholder="Yeni Şifreniz">
          </div>
          <div style="display:flex">
            <button @click="changePassword"><i class="fa fa-save"></i> Şifreyi Güncelle</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.mediaCdn,
      user: this.info.user.user,
      formError: null,
      savingPassword: false,
      currentPass: null,
      newPass: null,
      passwordError: null,
      passSuccess: null
    }
  },
  methods: {
    changePassword: async function () {
      this.savingPassword = true
      if (!this.currentPass) {
        this.passwordError = 'Mevcut şifrenizi girmelisiniz'
        this.savingPassword = false
        return
      }
      if (!this.newPass) {
        this.passwordError = 'Yeni şifrenizi girmelisiniz'
        this.savingPassword = false
        return
      }
      const payload = {
        user: this.$store.state.user,
        currentPass: this.currentPass,
        newPass: this.newPass
      }
      await axios.post('/api/saveChangePassword.php', payload).then(response => {
        const data = response.data
        const error = data.error
        if (error !== 'none') {
          this.passwordError = error
        }
        setTimeout(() => {
          if (error === 'none') {
            this.passSuccess = true
          }
          this.savingPassword = false
        }, 1000)
      })
    },
    openModal: function (stockId) {
      this.showFileUploader = true
      this.stockId = stockId
      setTimeout(() => {
        this.closeModal()
      }, 1500)
    },
    closeModal: function () {
      this.showFileUploader = false
      this.stockId = null
    },
    showProfileModal: function () {
      this.profileModal = !this.profileModal
    }
  }
}
</script>

<style scoped lang="scss">
.formError {
  width:calc(100% - 24px);
  padding:8px;
  font-size: 13px;
  border:2px dashed orangered;
  border-radius: 8px;
  margin-bottom: 12px;
}
.grid2 {
  display:grid; grid-template-columns: 1fr 1fr; gap:30px;margin-bottom:20px
}
.card {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  p {
    margin-bottom: 8px;
  }
}
button {
  border-radius:3px;
  border: 1px solid var(--color2);
  outline: none;
  background: var(--color2);
  color: #fff;
  padding: 7px 15px 7px 0px;
  transition: all 0.1s ease-in;
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  text-align: left;
  font-size: 12px;
  i {
    text-align: center;
    font-size: 13px;
  }
}
button:hover {
  background:#333;
  border-color: #333;
  color: #fff;
}
form {
  width:440px;
  div {
    display: grid;
    grid-template-columns: 1fr;
    gap:3px;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 18px;
    width:94%;
    label {
      padding-left:2px;
    }
    input {
      border-radius: 5px;
      border: 1px solid #ccc;
      padding:12px;
      width:100%;
      margin: auto;
    }
  }
}
label {
  font-size: 11px;
}
label a {
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
}
label a:hover {
  color:orangered;
}
@media screen and (max-width: 768px) {
  .grid2 {
    grid-template-columns:1fr;
  }
  form {
    width:320px;
  }
}
</style>
