<template>
  <div class="container mb">
    <div class="trendyol">
      <div class="item" v-for="item in info.subs" :key="item.id">
        <router-link :to="item.sUrl">
          <img :src="cdn + item.source" :alt="item.title" :title="item.title" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.trendyol {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  .item {
    img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .trendyol {
    grid-template-columns: 1fr;
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
}
</script>
