<template>
  <div id="app">
    <router-view ref="app" :info="info" v-if="isLoaded"/>
  </div>
</template>

<script>
// import { Toast } from '@capacitor/toast'
import axios from 'axios'

// const { Toast } = Toast

export default {
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {
    info () {
      return this.$store.state.info
    }
  },
  mounted () {
    this.$root.statusBar()
  },
  beforeMount () {
    this.getInfo()
  },
  watch: {
    $route (to, from) {
      document.querySelector('body').style.overflow = 'auto'
      this.$store.dispatch('setBottomMenu', false)
    }
  },
  methods: {
    /*
    showHelloToast: async function () {
      await Toast.show({
        text: 'Hello!'
      })
    },
    */
    showHelloToast: function () {
      alert('test')
    },
    getInfo: function () {
      const menu = (this.$store.state.info.menu)
      const sId = localStorage.getItem('sId')
      if (!menu) {
        const payload = {
          sId: sId
        }
        axios.post('api/info.php', payload).then(response => {
          if (response.data.error) {
            localStorage.removeItem('sId')
            this.getInfo()
          } else {
            this.$store.dispatch('setInfo', response.data)
            this.$store.dispatch('setUser', response.data.user)
            this.$store.dispatch('setCart', response.data.user.cartCount)
            localStorage.setItem('sId', response.data.user.sId)
          }
        }).then(response => {
          this.isLoaded = true
        })
      }
    }
  }
}
</script>

<style>
  @import './assets/css/main.css';
</style>
