<template>
  <div class="priceBox">
    <div><b style="font-size: 12px;color: #666;font-weight: bold;">Toplam Fiyat</b></div>
    <div style="color: #777;">KDV Dahil</div>
    <div class="bigPrice" v-if="totalPrice > 0">
      {{ totalPrice.toFixed(2) }}
      <span style="font-size: 23px;margin-left:3px;font-weight: bold;">TL</span></div>
    <div class="kargo">Ücretsiz Kargo</div>
    <div v-if="!isSaving">
      <button v-if="totalPrice > 0" @click="addToCart" class="sptButton2"><i class="fa fa-shopping-basket" style="font-size: 16px;"></i> Sepete Ekle</button>
    </div>
    <div v-else>
      <button class="sptButton2" style="cursor:default;background-color:red"><span class="loadingCart"></span>Ekleniyor</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['totalPrice', 'isSaving'],
  methods: {
    addToCart: function () {
      this.$parent.addToCart()
    }
  }
}
</script>

<style scoped lang="scss">
.priceBox {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  position: sticky;
  top: 20px;
  height: 180px;
  place-items: center;
  width: 100%;
  box-shadow: 0px 40px 40px -20px rgba(0, 0, 0, 0.26);
  div{
    font-size: 11px;
    text-align:center;
    line-height: 13px;
  }
}
@media screen and (max-width:768px) {
  .priceBox {
    box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.26);
    position: unset;
    height: unset;
  }
}
.sptButton2{
  display: flex;
  place-items: center;
  gap: 5px;
  justify-content: center;
  background: #40bbf4;
  border: none;
  outline: none;
  font-size: 18px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 34px;
  color: white;
  cursor: pointer;
  transition: all 0.1s;
}
.sptButton2:hover {
  filter: brightness(var(--bri));
}
.topFiyat {
  font-size: 11px;
}
.bigPrice {
  margin-top: 6px;
  font-size: 30px !important;
  color: #444;
  font-weight: bold;
  line-height: 40px !important;
}
.kargo {
  margin-top: 3px;
  margin-bottom: 12px;
  color: #226e4b;
  font-size: 13px !important;
  font-weight: bold;
}
.loadingCart {
  background:url(~@/assets/loading-cart.gif) center no-repeat;
  display:inline-block;
  width:40px;
  height:18px;
}
</style>
