<template>
  <div class="container mb">
    <div class="flex">
      <div class="item" v-for="item in info.subs" :key="item.id">
        <router-link :to="item.sUrl">
          <img style="width:100%" :src="cdn + '' + item.source " />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex {
  display:flex;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .flex {
    display:grid;
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
}
</script>
