<template>
  <div style="max-width:400px; margin:auto">
    <BarcodeScanner v-if="showScanner" :sound="sound" />
    <div class="barcode">
      <input id="barcode" @keypress.enter="getProduct" type="number" pattern="\d" v-model="barcode" placeholder="Barkod Okutun">
      <button @click="showScanner= true"><i class="fa fa-qrcode"></i></button>
      <button v-if="!sound" @click="toggleSound"><i class="fa fa-volume-mute"></i></button>
      <button v-else @click="toggleSound"><i class="fa fa-volume-up"></i></button>
    </div>
    <div v-if="!isLoading">
      <div style="margin-top:54px;padding:15px;" v-if="product.p">
        <div v-if="product.images.length > 0" style="margin-bottom:12px;border:1px solid #ccc;border-radius:4px; overflow:hidden">
          <splide :options="options" class="prSplide">
            <splide-slide v-for="image in product.images" :key="image.source" class="sss">
              <img width="100%" :src="mediaCdn + '250_' + image.source">
            </splide-slide>
          </splide>
        </div>
        <div class="box">
          <h2>{{ product.p.urunlerAdi }}</h2>
          <p class="row"><span>Barkod</span><span>{{ product.barcode }}</span></p>
          <p class="row"><span>Stok Kodu</span><span>{{ product.p.urunlerKod }}</span></p>
        </div>
        <div class="box" v-if="product.prices.length > 0">
          <b style="font-size:12px;color:red">Fiyat Bilgisi</b>
          <p v-for="item,index in product.prices" :key="index" class="row" style="align-items:center;border:1px solid #ccc">
            <span>{{ item.title }}</span>
            <span style="display:grid;align-items:center;font-size:11px;">{{ item.price.toFixed(2) }} TL + KDV <span><span style="font-weight:bold;font-size:21px;">{{ item.salePrice.toFixed(2) }} TL</span></span></span>
          </p>
        </div>
        <div class="box" v-if="product.qtys.length > 0">
          <b style="font-size:12px;color:red">Stok Bilgisi</b>
          <p v-for="item,index in product.qtys" :key="index" class="row" style="align-items:center;border:1px solid #ccc">
            <span>{{ item.title }}</span>
            <span style="font-weight:bold;font-size:21px;">{{ item.qty }} Adet</span>
          </p>
        </div>
        <div class="box" v-if="product.specs">
          <b style="font-size:12px;color:red">Ürün Bilgisi</b>
          <div v-html="product.specs"></div>
        </div>
      </div>
    </div>
    <div v-else style="margin-top:54px;padding:15px;">
      <div class="loading"><img src="@/assets/loading-pulse.svg"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BarcodeScanner from '@/components/BarcodeScanner.vue'

export default {
  components: { BarcodeScanner },
  data: function () {
    return {
      barcode: '',
      product: {},
      showScanner: false,
      sound: true,
      isLoading: false,
      mediaCdn: this.$store.state.mediaCdn,
      options: {
        rewind: true,
        perpage: 1,
        autoWidth: false,
        autoHeight: true,
        arrows: false,
        pagination: true,
        lazyLoad: false,
        autoplay: false,
        type: 'loop',
        keyboard: false
      }
    }
  },
  mounted () {
    const sound = localStorage.getItem('sound')
    if (sound === 'false') {
      this.sound = false
    } else {
      this.sound = true
    }
    this.getProduct()
  },
  methods: {
    toggleSound: function () {
      this.sound = !this.sound
      localStorage.setItem('sound', this.sound)
    },
    sp: function (images) {
      this.product.images = images
    },
    setFileGroups: function (printSize, index) {
      this.sizeName = printSize.sizeName
      this.fileGroups = printSize.files
      this.fileGroup = printSize.files[0]
      this.fileGroupIndex = index
    },
    getProduct: async function () {
      console.clear()
      this.product = {
        images: []
      }
      if (!this.barcode) {
        return
      }
      this.isLoading = true
      const payload = {
        barcode: this.barcode,
        user: this.$store.state.user
      }
      await axios.post('api/barcode.php', payload).then(response => {
        this.isLoading = false
        this.barcode = ''
        document.getElementById('barcode').blur()
        if (response.data.error === 'none') {
          this.product = response.data.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  padding:4px;border:1px solid #ccc;border-radius:4px;margin-bottom:12px;
  font-family:arial;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
h2 {
  font-size: 16px;
}
p {
  font-size: 12px;
  margin-bottom: 2px;
}
.row {
  display: grid;
  grid-template-columns: 120px auto;
  padding: 3px;
}
.barcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: fixed;
  top: 0px;
  left:10px;
  width: calc(100vw - 20px);
  background:#fff;
  padding: 5px;
  padding-top:10px;
  z-index: 100;
  button {
    width: 70px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    background: #dddddd;
    i {
      font-size: 20px;
    }
  }
  button:last-child {
    margin-right: 0;
  }
  input {
    width: 100%;
    margin-right: 10px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    outline: none;
    background: #fff;
    transition: all 0.1s ease;
    padding-left:26px;
    background:url(~@/assets/barcode.svg) no-repeat left center;
    background-size: 15px 15px;
    background-position: 7px;
    &:focus {
      border: 1px solid #333;
      box-shadow: 0 0 3px #333;
    }
  }
}
.images {
  img {
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
</style>
