<template>
  <div class="bcContainer" v-if="info">
    <div class="breadCrumb">
      <ul>
        <li>
          <router-link :to="'/'"><span class="fa fa-home"></span> <span v-if="$fnc.getWidth() > $store.state.mobileWidth">Anasayfa</span></router-link>
          <i class="fa fa-angle-right"></i>
        </li>
        <li v-for="(item, idx) in info" :key="idx">
          <div v-if="item.title !== 'sütun'">
            <div v-if="info.length > (idx + 1) && item.title">
              <router-link
              :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: info[0].id }}">{{ item.title }}</router-link>
              <i class="fa fa-angle-right"></i>
            </div>
            <div v-else style="color:#666">
              {{ item.title }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      bcWidth: 800
    }
  }
}
</script>

<style scoped lang="scss">
.bcContainer {
  width:100%;
  overflow:hidden;
}
.breadCrumb {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  font-family: var(--font2);
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom:15px;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    li {
      display: block;
      a {
        color: #919191;
      }
      a:hover {
        color:var(--color1);
      }
      i {
        color: #c9c9c9;
        font-size: 11px;
        margin-left:12px;
        margin-right:12px;
      }
    }
  }
}
</style>
