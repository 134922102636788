<template>
  <div v-if="p" class="adets" style="margin-top:10px">
    <div class="selections">
      <div class="title">{{ p.pageTitle }}</div>
      <div class="buttonset" style="display:grid;grid-template-columns:1fr">
        <div v-for="item in p.p" :key="item.setId">
          <button @click="$parent.selectBundle(item)"
          :class="{ 'activeButton': item.barcode === $parent.selectedBundle.barcode }"
          style="display:grid;grid-template-columns:80px auto;min-height:60px">
            <div v-if="item.image"><img height="48" :src="$parent.mediaCdn + '64_' + item.image" /></div>
            <div v-else style="height:48px"></div>
            <div>
              <div style="color:black;font-size:13px;text-align:left;display:grid;grid-template-columns:auto 35px;align-items:center">
                <div>{{ item.title }}</div>
                <div @click="test(item.title, item.description)" v-if="item.description" style="width:20px;height:20px;background:#40bbf4;border-radius:50%;display:grid;place-items:center">
                  <i style="font-size:10px;margin-top:-1px;color:#fff" class="fa fa-info"></i>
                </div>
              </div>
              <div v-if="item.price">
                <div style="text-align:left">
                  <b v-if="item.listPrice > item.price" style="font-size:11px;text-decoration:line-through;margin-right:6px">{{ item.listPrice.toFixed(2) }}TL</b>
                  <b style="font-size:14px;color:red">+{{ item.price.toFixed(2) }}TL</b>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div style="margin-top:8px" v-html="p.description"></div>
    <GlassModal v-if="modal" @close="closeModal">
      <template v-slot:title>{{ title }}</template>
      <template v-slot:body>
        <div v-html="aciklama"></div>
      </template>
    </GlassModal>
  </div>
</template>

<style scoped lang="scss">
.selections {
  .title {
    margin-bottom: 6px;
    font-size: 12px;
  }
}
</style>

<script>
export default {
  props: ['p'],
  data: function () {
    return {
      modal: false,
      title: '',
      aciklama: ''
    }
  },
  methods: {
    test: function (title, aciklama) {
      this.modal = true
      this.title = title
      this.aciklama = aciklama
      // alert(str)
    },
    closeModal: function () {
      this.modal = false
    }
  }
}
</script>
