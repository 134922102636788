<template>
  <div>
    <HeroParallax v-if="hero" :info="hero" />
    <div class="container">
      <BreadCrumb :info="breadCrumb" />
      <div class="catalog" :class="{ catalogNoFilter: !showFilters }">
        <div class="filters" v-if="showFilters">
          <div class="block" v-if="subCats.length > 0">
            <ul v-for="item in subCats" :key="item.id" class="subs">
              <b v-if="item.title !== 'sütun'">{{ item.title }}</b>
              <li v-for="item2 in item.subs" :key="item2.id" style="padding-left:0px">
                <div v-if="item2.title !== 'sütun'">
                  <router-link style="font-weight:bold"
                    :to="{ name: 'Products', params: { id: item2.id + '-' + $fnc.seo(item2.title), top: item.id }}">{{ item2.title }}
                  </router-link>
                </div>
                <ul style="padding-left:8px">
                  <li v-for="item3 in item2.subs" :key="item3.id" style="margin-bottom:12px">
                    <router-link style="font-weight:bold"
                      :to="{ name: 'Products', params: { id: item3.id + '-' + $fnc.seo(item3.title), top: item.id }}">{{ item3.title }}
                    </router-link>
                    <ul style="padding-left:8px">
                      <li v-for="item4 in item3.subs" :key="item4.id">
                        <router-link v-if="!item4.url"
                          :to="{ name: 'Products', params: { id: item4.id + '-' + $fnc.seo(item4.title), top: item.id }}">{{ item4.title }}
                        </router-link>
                         <router-link v-else
                          :to="item4.url">{{ item4.title }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="block" v-if="allBrands.length > 0">
            <p>Markalar</p>
          </div>
          <div class="block" v-if="allFilters.length > 0">
            <p>Seçenekler</p>
          </div>
        </div>
        <div class="products">
          <CatInfo v-if="!isTopCat" :info="{ totalProducts: totalProducts, title: cat.title }" />
          <div v-if="!isTopCat">
            <div class="list" style="margin-top:20px;">
              <ProductCard v-for="p in products" :key="p.id" :p="p"  />
            </div>
            <Pager :id="id" :brands="brands" :filters="filters" :page="page" :pageRange="pageRange" :totalProducts="totalProducts" :pageSize="pageSize"/>
          </div>
          <div v-else>
            <Collection class="besli" v-for="item in collections" :key="item.id" :info="item" :listClass="'list4'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import HeroParallax from '@/small-components/HeroParallax.vue'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import CatInfo from '@/small-components/CatInfo.vue'
import ProductCard from '@/small-components/ProductCard.vue'
import Pager from '@/small-components/Pager.vue'
import Collection from '@/home-components/Collection.vue'

export default {
  components: { HeroParallax, BreadCrumb, CatInfo, ProductCard, Pager, Collection },
  data () {
    return {
      products: [],
      id: this.$route.params.id,
      brands: this.$route.query.brands,
      filters: this.$route.query.filters,
      page: parseInt(this.$route.query.p),
      sort: this.$store.state.sort,
      totalProducts: 0,
      pageSize: this.$store.state.pageSize,
      pageRange: this.$fnc.pageRange(0, { min: 1, total: 1, length: 1 }),
      cat: {},
      showFilters: true,
      subCats: [],
      allBrands: [],
      allFilters: [],
      breadCrumb: [],
      isTopCat: false,
      collections: [],
      hero: {
        title: 'Sığınacak bir liman',
        text: 'Herkes gibi, olmayan hayaller peşinde yalnızım, kelimeler acıtır canımızı bilmeden el yordamıyla koşarken. Dinle artık duymuyoruz yalama hayaller sonunda dünya, dünya. Dünya aslında yanı başımızda',
        color: '#FFF',
        src: '',
        show: false
      }
    }
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.brands = this.$route.query.brands
      this.filters = this.$route.query.filters
      this.page = parseInt(this.$route.query.p)
      this.getProducts()
    },
    '$store.state.sort': function () {
      this.sort = this.$store.state.sort
      if (this.page === 1) {
        this.getProducts()
      } else {
        this.$router.push({
          name: 'Products', query: { brands: this.brands, filters: this.filters }
        })
      }
    }
  },
  beforeMount () {
    this.getProducts()
  },
  methods: {
    getProducts: async function () {
      this.products = []
      var page = 1
      if (this.page) {
        page = parseInt(this.page)
      } else {
        this.page = 1
      }
      const payload = {
        id: parseInt(this.$route.params.id),
        brands: this.$route.query.brands,
        filters: this.$route.query.filters,
        page: page,
        sort: this.sort,
        pageSize: this.pageSize,
        top: parseInt(this.$route.params.top),
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId
      }
      await axios.get('api/products.php', { params: payload }).then(response => {
        const data = response.data
        this.products = data.catalog
        this.totalProducts = data.total
        this.cat = data.cat
        this.pageSize = data.pageSize
        var length = 10
        this.isTopCat = data.isTopCat
        this.collections = data.subs
        if (this.$fnc.getWidth() < this.$store.state.mobileWidth) {
          length = 6
        }
        this.pageRange = this.$fnc.pageRange(this.page, { min: 1, total: Math.ceil(data.total / data.pageSize), length: length })
        this.subCats = data.subMenus
        this.breadCrumb = data.bc
      })
    }
  }
}
</script>
<style scoped lang="scss">
.besli {
  .list {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
.products {
  min-height: 800px;
}
ul.subs {
  user-select: none;
  list-style:none;
  li{
    a {
      font-size: 13px;
      color:#666;
    }
    a:hover, .active {
      color:var(--color1);
    }
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-top: 1px;
        padding-bottom: 1px;
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.catalog {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 20px;
  margin-bottom: 30px;
  .filters {
    border-right:1px solid #ddd;
    padding-right:20px;
    .block {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      p {
        font-weight: bold;
        font-size: 15px;
      }
      ul {
        list-style:none;
      }
    }
  }
}
.catalogNoFilter {
  grid-template-columns: 1fr;
}
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  min-height: 1800px;
  min-height: 400px;
  grid-auto-rows: min-content;
}
@media screen and (max-width: 320px) {
  .list {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
@media screen and (min-width:321px) and (max-width: 768px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
@media screen and (min-width:768px) and (max-width: 1024px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
}
</style>
