import Vue from 'vue'
import App1 from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSplide from '@splidejs/vue-splide'
import x5GMaps from 'x5-gmaps'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import { Toast } from '@capacitor/toast'
import { StatusBar } from '@capacitor/status-bar'
import { Browser } from '@capacitor/browser'

import '@splidejs/splide/dist/css/themes/splide-default.min.css'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import Header from '@/components/Header.vue'
import GlassModal from '@/components/GlassModal.vue'
import SimpleHeader from '@/components/SimpleHeader.vue'
import Home from '@/components/Home.vue'
import Footer from '@/components/Footer.vue'
import SimpleFooter from '@/components/SimpleFooter.vue'
import Products from '@/components/Products.vue'
import StarRating from 'vue-star-rating'
import VTooltip from 'v-tooltip'
import { fnc } from './fnc.js'
import * as rax from 'retry-axios'

Vue.component('Header', Header)
Vue.component('GlassModal', GlassModal)
Vue.component('Home', Home)
Vue.component('Footer', Footer)
Vue.component('Products', Products)
Vue.component('SimpleHeader', SimpleHeader)
Vue.component('SimpleFooter', SimpleFooter)
Vue.component('StarRating', StarRating)
Vue.use(VueSplide)
Vue.use(VTooltip)
Vue.use(VueToast, {
  position: 'top-right'
})

Vue.use(VueAxios, axios)
Vue.use(x5GMaps, {
  key: 'AIzaSyBqD23PQG_GhFUZTPpO0TKFSG0TSrCHaAM',
  libraries: ['visualization', 'places']
})

axios.defaults.baseURL = 'https://graficopy.com.tr/'
function detectLanguage () {
  let locale = ''
  const savedLocale = localStorage.getItem('lang')
  if (savedLocale) {
    locale = savedLocale
  } else {
    const bLocale = window.location.pathname.replace('/', '').trim().substring(0, 2)
    const langs = ['tr', 'en']
    if (langs.includes(bLocale)) {
      locale = bLocale
    } else {
      locale = 'tr'
    }
  }
  return locale
}
const lang = detectLanguage()
axios.defaults.headers.common.lang = lang
document.querySelector('html').setAttribute('lang', lang)
const retry = 6
axios.defaults.raxConfig = {
  instance: axios,
  retry: retry,
  noResponseRetries: retry,
  retryDelay: 750,
  httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'POST'],
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err)
    if (cfg.currentRetryAttempt === retry) {
      Vue.$toast.error('İşlem tamamlanamadı, lütfen sayfayı yenileyin', {
        position: 'bottom-right',
        duration: 5000,
        onClick: function () {
          location.reload()
        }
      })
    }
    console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
  }
}
rax.attach(axios)

App.addListener('appStateChange', ({ isActive }) => {
  // console.log('App state changed. Is active?', isActive)
})
App.addListener('backButton', ({ BackButtonListener }) => {
  // console.log('App state changed. Is active?', BackButtonListener)
  window.history.back()
})

// axios.defaults.timeout = 1200
Vue.config.productionTip = false
Vue.prototype.$fnc = fnc
new Vue({
  data: {
    toast: async function (msg) {
      const d = await Device.getInfo()
      if (d.platform !== 'web') {
        await Toast.show({
          text: msg
        })
      } else {
        Vue.$toast.open(msg)
      }
    },
    statusBar: async function () {
      // StatusBar.setOverlaysWebView({ overlay: true })
      const d = await Device.getInfo()
      if (d.platform === 'android') {
        StatusBar.hide()
      }
      // StatusBar.setStyle({ style: Style.Light })
    },
    openBrowser: async function (url) {
      await Browser.open({ url: url })
    }
  },
  router,
  store,
  render: h => h(App1)
}).$mount('#app')
