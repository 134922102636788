<template>
  <div>
    <Header :info="info" />
    <div class="container" v-if="page">
      <BreadCrumb :info="[{ id:page.id, title: page.title }]" />
      <div class="mb catalog" :class="{ otherPages: !otherPages }">
        <div class="filters" v-if="otherPages">
          <div class="block">
            <b></b>
            <ul>
              <li v-for="item in otherPages" :key="item.id">
                <router-link :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: item.cat_id }}">{{ item.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="images.length > 0">
          <img width="100%" class="desktop" :src="cdn + images[0].source" :alt="page.title" />
          <img width="100%" class="mobile" :src="cdn + images[1].source" :alt="page.title" />
        </div>
        <JsonPageNormal :page="page" />
      </div>
    </div>
    <div v-else class="container">
      <BreadCrumb :info="[{ id:0, title: '' }]" />
      <div class="catalog mb" style="grid-template-columns: 1fr; place-items:center;">
        <img src="../assets/loading-pulse.svg">
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.otherPages {
  grid-template-columns: 1fr !important;
}
.catalog {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh - 500px);
  .filters {
    border-right:1px solid #ddd;
    padding-right:20px;
    user-select: none;
    .block {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      p {
        font-weight: bold;
        font-size: 15px;
      }
      ul {
        user-select: none;
        list-style:none;
        padding-left: 12px;
        li{
          a {
            font-size: 13px;
            color:#666;
          }
          a:hover, .active {
            color:var(--color1);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>

<script>
import axios from 'axios'
import JsonPageNormal from '@/components/JsonPageNormal'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
export default {
  components: { JsonPageNormal, BreadCrumb },
  props: ['info'],
  data () {
    return {
      id: null,
      parent: null,
      pageType: null,
      cdn: this.$store.state.fileCdn,
      page: {},
      otherPages: [],
      images: []
    }
  },
  beforeMount () {
    this.getPage()
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.parent = this.$route.params.parent
      this.pageType = this.$route.name
      this.page = null
      this.getPage()
    }
  },
  methods: {
    getPage: async function () {
      const payload = {
        id: parseInt(this.$route.params.id),
        parent: this.$route.params.parent,
        sId: this.$store.state.user.sId,
        pageType: this.pageType,
        userId: this.$store.state.user.userId
      }
      await axios.post('api/getPage.php', payload).then(response => {
        setTimeout(() => {
          this.page = response.data.page
          this.images = response.data.images
          this.otherPages = response.data.otherPages
        }, 0)
      })
    }
  }
}
</script>
