<template>
  <div>
    <div v-if="info.orders.length < 1">
      <p>Daha önce sipariş vermediniz.</p>
    </div>
    <div class="row" v-for="item in info.orders" :key="item.faturaId" :class="{ 'activeRow': item.faturaId === activeOrder}">
      <div class="faturaRow" @click="selectActive(item.faturaId)">
        <div class="grid2">
          <div>{{ item.faturaNo }}</div>
          <div class="right">{{ item.faturaTarih }}</div>
        </div>
        <div class="grid2">
          <div>{{ item.faturaDurum }}</div>
          <div class="right">{{ item.faturaTutar.toFixed(2) }}₺</div>
        </div>
        <div class="buttonSet right" style="width:30px">
          <button v-if="activeOrder !== item.faturaId" style="background:teal;color:white"><i class="fa fa-angle-down"></i></button>
          <button v-else><i class="fa fa-times"></i></button>
        </div>
      </div>
      <div class="faturaDetails" v-if="activeOrder === item.faturaId">
        <div class="items">
          <ul style="">
            <li v-for="stock in item.items" :key="stock.id">
              <div class="ustRow">
                <span style="padding:12px">
                  <router-link :to="{ name: 'Product', params: { pid: stock.pid } }">
                    <img v-if="stock.image" class="ovalImage" :src="cdn + stock.image" width="100%">
                    <img v-else class="ovalImage" :src="cdn + '1200_no-image.png'" width="100%">
                  </router-link>
                </span>
                <span><router-link :to="{ name: 'Product', params: { pid: stock.pid } }" v-html="stock.title">{{ stock.title }}</router-link></span>
                <span style="text-align:right">{{ stock.qty }} Ad.</span>
                <span style="text-align:right">{{ stock.tutar.toFixed(2) }} ₺</span>
              </div>
              <div class="altRow" style="padding-bottom:12px" v-if="stock.options">
                <span style="margin-right:12px;font-weight:bold;font-size:12px" v-for="option in stock.options" :key="option.id">
                  <span>{{ option.tipName}}: </span>
                  <span>{{ option.name}}, </span>
                </span>
                <div style="margin-top:16px;display:flex;">
                  <button @click="openReviewModal(stock)" class="btn reviewBtn"><i class="fa fa-thumbs-up"></i> Ürünü Değerlendir</button>
                  <button @click="openModal(stock.id)" class="btn fileUploadBtn"><i class="fa fa-upload"></i> Tasarımını Yükle</button>
                </div>
              </div>
              <div v-else class="altRow" style="padding-bottom:22px">
                <div style="margin-top:16px; display:flex; gap:12px" v-if="stock.image">
                  <button @click="openReviewModal(stock)" class="btn reviewBtn"><i class="fa fa-thumbs-up"></i> Ürünü Değerlendir</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <GlassModal v-if="showFileUploader" @close="closeModal">
      <template v-slot:title>Dosyalarınızı yükleyin</template>
      <template v-slot:body>
        <div v-if="!userFiles">
          <p>Dosyalarınızı lütfen <b>PDF</b>, <b>Aİ</b> veya <b>PSD</b> formatında yükleyin</p>
          <div style="padding:12px;height:100px;display:grid;place-items:center;" v-if="uploading">
            <div>
              <p><progress :value="percent" max="100">%{{ percent }}</progress></p>
              <p v-if="!uploadMessage">Dosya Yükleniyor %{{ percent }}</p>
              <p v-else> {{ uploadMessage }} %100</p>
            </div>
          </div>
          <div class="filePicker" v-else>
            <div style="display:grid;grid-template-columns:auto 120px; gap:10px; align-items:center;padding:12px;height:100px">
              <div class="formRow" style="grid-template-columns:1fr">
                <div>
                  <input id="files" type="file" :accept="fileData.allowed_exts" :multiple="fileData.multiple">
                </div>
              </div>
              <button @click="uploadFiles" style="width:100%" class="btn2"><i class="fa fa-upload"></i> Dosyayı Yükle</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="item in userFiles" :key="item.id" class="fileRow">
            <a :href="userCdn + item.source" target="_blank" style="display:grid; grid-template-columns:75px auto;align-items:center">
              <div class="icon" v-if="item.thumb === '1'">
                <img width="128" :src="userCdn + '128_' + item.source">
              </div>
              <div v-else>
                <div :class="'icon'">
                  <p>.{{ item.ext }}</p>
                </div>
              </div>
              <p>
                {{ item.title }}.{{ item.ext }}<br>
                <span style="font-size:11px;font-weight:bold;">{{ item.message }}</span>
              </p>
            </a>
            <div style="text-align:right">
              <button @click="deleteFile(item.id)" class="deleteBtn">
                Dosyayı Sil
              </button>
            </div>
          </div>
        </div>
      </template>
    </GlassModal>
    <GlassModal v-if="showReviewModal" @close="closeReviewModal">
      <template v-slot:title>Ürünü Değerlendirin</template>
      <template v-slot:body>
        <div v-if="!isSavingReview">
          <div style="display:grid; grid-template-columns: 40px auto;gap:6px; align-items:center">
            <img v-if="stock.image" class="ovalImage" :src="cdn + stock.image" width="100%">
            <p style="font-weight:bold;font-family:arial" v-html="stock.title"></p>
          </div>
          <div v-if="review.c_id > 0" style="display:grid;place-items:center;padding-top:60px;padding-bottom:60px;text-align:center">
            <p><i style="color:green;font-size:64px" class="fa fa-check-circle"></i></p>
            <h3 style="margin-top:22px;">Değerlendirmeniz bize başarıyla ulaştı</h3>
            <p style="font-size:13px;margin-top:12px">Yorumlarınız
              <router-link style="color:red" target="_blank" :to="{ name: 'Page', params: { id: 61 + '-' + $fnc.seo('ön-bilgilendirme-metni'), parent: 1 }}">Ön Bilgilendirme Metni</router-link>
            'nde belirtilen kapsamda işlenecek ve yayınlanacaktır.</p>
          </div>
          <div v-else>
            <div style="display:grid;place-items:center;padding:9px; min-width:320px">
              <star-rating v-model="review.rating" :star-size="30" :show-rating="false" />
            </div>
            <div style="margin-top:8px">
              <p>Ürün hakkındaki düşünceleriniz</p>
              <textarea v-model="review.comment" rows="5" style="width:100%; border-radius:6px;margin-top:12px;margin-bottom:12px;border-color:#ccc;padding:12px"></textarea>
            </div>
            <div style="margin-bottom:12px">
              <p style="margin-bottom:8px;" v-if="review.showUserName">
                {{ userName[0].split('')[0] }}****
                <span v-if="userName[1]">
                  {{ userName[1].split('')[0] }}****
                </span>
                <span v-if="userName[2]">
                  {{ userName[2].split('')[0] }}****
                </span>
              </p>
              <p style="margin-bottom:8px;" v-else>
                {{ userName[0] }}
                <span v-if="userName[1]">
                  {{ userName[1].split('')[0] }}****
                </span>
                <span v-if="userName[2]">
                  {{ userName[2].split('')[0] }}****
                </span>
              </p>
              <label>
                <input v-model="review.showUserName" type="checkbox">
                İsmim Görünmesin
              </label>
            </div>
            <p style="font-size:13px;margin-top:12px;margin-bottom:12px">Yorumlarınız
              <router-link style="color:red" target="_blank" :to="{ name: 'Page', params: { id: 61 + '-' + $fnc.seo('ön-bilgilendirme-metni'), parent: 1 }}">Ön Bilgilendirme Metni</router-link>
            'nde belirtilen kapsamda işlenecek ve yayınlanacaktır.</p>
            <button @click="saveReview" class="btn" :class="{ 'fileUploadBtn': review.rating > 0 }"><i class="fa fa-save"></i> Değerlendirmeyi Kaydet</button>
          </div>
        </div>
        <div v-else style="display:grid; place-items:center; min-height:200px; min-width:320px">
          <img src="@/assets/loading-pulse.svg">
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.mediaCdn,
      activeOrder: null,
      showFileUploader: false,
      showReviewModal: false,
      stockId: null,
      stock: null,
      isSavingReview: false,
      savedReviewId: 0,
      userName: this.$store.state.user.userName.split(' '),
      review: {},
      fileData: {
        allowed_exts: '.pdf,.psd,.ai,.png',
        muiltple: false
      },
      newFileForm: false,
      uploading: false,
      percent: 0,
      uploadMessage: '',
      editFileName: 0,
      userFiles: false,
      userCdn: 'https://promosyonburada.com/userfiles/'
    }
  },
  mounted () {
    if (this.info.orders.length > 0) {
      this.selectActive(this.info.orders[0].faturaId)
    }
  },
  methods: {
    deleteFile: async function (id) {
      const r = confirm('Dosyayı gerçekten silmek istiyor musunuz?')
      if (r) {
        const payload = {
          user: this.$store.state.user,
          id: id
        }
        await axios.post('/api/deleteFile.php', payload).then(response => {
          this.openModal(this.stockId)
        })
      }
    },
    uploadFiles: async function () {
      var formData = new FormData()
      const files = document.querySelector('#files').files
      if (files.length < 1) {
        alert('Dosya seçmediniz')
        return
      }
      this.uploading = true
      files.forEach(f => {
        formData.append('files[]', f)
      })
      formData.append('page_id', this.stockId)
      formData.append('tablo', 'orderFiles')
      formData.append('allowed_exts', this.fileData.allowed_exts)
      formData.append('user', this.$store.state.user.userId)
      formData.append('sId', localStorage.getItem('sId'))
      const config = {
        onUploadProgress: (progressEvent) => {
          var percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          this.percent = percent
          if (percent === 100) {
            this.uploadMessage = 'Dosya işleniyor'
          }
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 0
      }
      const sId = localStorage.getItem('sId')
      await axios.post('api/uploadFile.php?sId=' + sId, formData, config).then(response => {
        const d = response.data
        console.log(d)
        if (d.error > 0) {
          this.$toast.error('Dosya yüklenemedi')
        } else {
          this.$toast.success('Dosya başarıyla yüklendi')
        }
        this.openModal(this.stockId)
        this.uploading = false
        this.percent = 0
        this.uploadMessage = ''
      }).then(response => {
        console.log('itsok')
        // this.getFiles()
      })
    },
    openModal: async function (stockId) {
      const payload = {
        user: this.$store.state.user,
        stockId: stockId
      }
      await axios.post('/api/getUserFiles.php', payload).then(response => {
        if (response.data.result.length > 0) {
          this.userFiles = response.data.result
        } else {
          this.userFiles = false
        }
        console.log(this.userFiles)
      })
      this.showFileUploader = true
      this.stockId = stockId
    },
    closeModal: function () {
      this.showFileUploader = false
      this.stockId = null
      this.userFiles = false
    },
    openReviewModal: async function (stock) {
      this.isSavingReview = true
      setTimeout(() => {
        this.showReviewModal = true
      }, 100)
      const payload = {
        user: this.$store.state.user,
        stockId: stock.id
      }
      await axios.post('/api/getReview.php', payload).then(response => {
        this.stock = stock
        const err = response.data.error
        console.log(err)
        const data = response.data.data
        let review = {}
        if (data.c_id) {
          review = {
            c_id: data.c_id,
            title: data.title,
            pid: data.pid,
            bid: data.bid,
            stockId: data.stock_id,
            comment: data.comment,
            image: data.image,
            showUserName: false
          }
        } else {
          review = {
            c_id: 0,
            title: stock.title,
            pid: stock.pid,
            bid: stock.bid,
            stockId: stock.id,
            comment: '',
            image: stock.image,
            showUserName: false
          }
        }
        this.review = review
        this.review.rating = 5
        this.isSavingReview = false
      })
    },
    closeReviewModal: function () {
      this.showReviewModal = false
      this.stock = null
      const review = {
        rating: 0,
        stockId: 0,
        pid: 0,
        bid: 0,
        title: '',
        comment: '',
        image: ''
      }
      this.review = review
    },
    saveReview: async function () {
      this.isSavingReview = true
      const payload = {
        user: this.$store.state.user,
        review: this.review
      }
      await axios.post('/api/saveReview.php', payload).then(response => {
        const err = response.data.error
        if (err === 'none') {
          console.log(response.data.data[0])
          setTimeout(() => {
            this.isSavingReview = false
          }, 1500)
          this.review.c_id = parseInt(response.data.data)
        } else {
          alert(err)
          this.isSavingReview = false
        }
      })
    },
    selectActive: function (orderNo) {
      if (this.activeOrder === orderNo) {
        this.activeOrder = null
      } else {
        this.activeOrder = orderNo
      }
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.deleteBtn {
  border:none;background:orange;color:white;padding:8px;border-radius:8px;
}
.fileRow {
  display: grid;
  grid-template-columns: auto 90px;
  align-items: center;
  border: 1px solid #ccc;
  padding: 12px;
  margin-bottom: 12px;
}
.icon {
  border:2px solid #ccc;
  height: 64px;
  width: 64px;
  padding:0px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  overflow: hidden;
  p {
    font-size: 24px;
    font-weight: bold;
  }
  img {
    width:100%;
  }
}
.fileUploadBtn {
  background:var(--color1);
}
.reviewBtn {
  background:var(--color2);
}
.btn {
 color:white; border:none;padding:8px;border-radius:4px;
 transition: 0.1s all ease-in;
 margin-right:10px;
}
.btn:hover {
  filter: brightness(1.2);
}
.filePicker {
  margin-top:6px;
  padding: 12px;
  background:#e5e5e5;
  border-radius:6px;
  display:grid;
  grid-template-columns:auto;
  gap:10px;
  place-items:center;
  input {
    width:100%;
  };
  button {
    font-size: 12px;
    width: 100%;
    padding:9px;
    border:none;
    border:1px solid #ccc;
    background:#fff;
    border-radius:6px;
    transition: all 0.1s ease-out;
  }
  button:hover {
    background: var(--color2);
    color:white;
  }
}
a {
  color:black;
  user-select: unset;
  user-select: text !important;
}
a:hover {
  color: var(--color1);
}
.ovalImage {
  padding:1px; border:1px solid #ccc;border-radius:50%;background:white;
  user-select: none;
  -webkit-user-drag: none;
}
.grid2 {
  display:grid;
  grid-template-columns: auto auto;
  .right {
    text-align:right;
  }
}
.buttonSet {
  text-align:center;
  button {
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border: none;
    background: #f1f1f1;
    border-radius: 50%;
  }
  button:hover {
    background: #e0e0e0;
  }
}
.row {
  // border: 1px solid #ccc;
  margin-bottom: 22px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  transition: all 0.1s ease-out;
}
.row:hover, .activeRow {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.faturaRow {
  padding: 10px;
  align-items: center;
  display:grid;
  grid-template-columns: 240px auto 40px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  div {
    padding: 8px;
  }
}
.items {
  background:#f3f3f3;
  border-radius: 6px;
  ul {
    list-style: none;
    padding: 0px;
    li {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 20px;
      .ustRow {
        display: grid;
        grid-template-columns: 100px auto 60px 60px;
        align-items: center;
        min-height: 90px;
      }
    }
    li:nth-child(even) {
       background: white;
    }
  }
}
@media screen and (max-width: 768px) {
  .faturaRow {
    grid-template-columns: 1fr;
  }
  .ustRow {
    grid-template-columns: 60px auto 60px 60px !important;
  }
}
</style>
