<template>
  <div>
    <div class="filesContainer tabContainer" v-if="fileGroups.length > 0">
        <span class="header">{{ title }} şablonları</span>
        <div class="tabHeader">
          <button v-for="(item, idx) in fileGroups" :key="item.title" :title="item.desc" @click="selectSubFiles(idx)" :class="{ 'active': idx === fileGroupIndex }">
            <span style="font-size:20px">.</span><span style="text-transform:capitalize;font-size:19px;">{{ item.title }}</span>
          </button>
        </div>
        <div style="margin-top:12px;overflow:hidden">
          <div v-for="item in fileGroup.files" :key="item.id">
            <transition appear name="slide-fade">
              <a class="listA" :href="fileCdn + item.source" target="_blank">
                <i style="margin-right:4px" class="fa fa-download"></i>
                {{ sizeName + ' ' + item.title + '.' + item.ext }}
              </a>
            </transition>
          </div>
        </div>
      </div>
      <div class="filesContainer" style="margin-top:16px;" v-if="subList">
        <div>
          <ul style="list-style:none;">
            <li><a class="listA" href=""><i class="fa fa-angle-right"></i> b1</a></li>
            <li><a class="listA" href=""><i class="fa fa-angle-right"></i> b1</a></li>
            <li><a class="listA" href=""><i class="fa fa-angle-right"></i> b1</a></li>
            <li><a class="listA" href=""><i class="fa fa-angle-right"></i> b1</a></li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ['fileGroups', 'fileGroup', 'fileGroupIndex', 'sizeName', 'title'],
  data: function () {
    return {
      fileCdn: this.$store.state.fileCdn,
      subList: false
      // fileGroup: this.fileGroups[0],
      // fileGroupIndex: 0
    }
  },
  methods: {
    selectSubFiles: function (idx) {
      this.fileGroup = this.fileGroups[idx]
      this.fileGroupIndex = idx
      console.clear()
    }
  }
}
</script>

<style scoped lang="scss">
.filesContainer{
  margin-top: 22px !important;
  border: 1px solid #929292;
  padding: 14px;
  border-radius: 4px;
  .header {
    color:#666;
    margin-top:-24px;
    font-size: 12px;
    position:absolute;
    padding-left:4px;
    padding-right:4px;
    background:var(--bodyBg);
    text-transform: capitalize;
  }
}
.tabContainer {
  font-family: var(--font1);
  margin-top:1px;
  .tabHeader {
    display:flex;
    justify-content:center;
    border-bottom:3px solid #ccc;
    padding-bottom:3px;
    margin-top:0px;
    margin-bottom:0px;
    button {
      user-select: none;
      font-weight: bold;
      border: none;
      background: var(--white);
      border-bottom: 2px solid var(--bodyBg);
      position: relative;
      outline: none;
      border-radius: 8px;
      font-family: var(--font2);
      background:none;
      outline: none;
      color: #ccc;
      padding-left: 23px;
      padding-right: 23px;
      cursor: pointer;
    }
    button:after {
      margin-bottom: -8px;
      height: 3px !important;
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: var(--color2);
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    button:hover:after {
      width: 100%;
      left: 0;
    }
    .active {
      color: var(--color2)
    }
    .active:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      background: var(--color2);
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 100%;
      left: 0;
    }
  }
}
.listA {
  color:#666;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  display:block
}
.listA:hover {
  color:orangered;
  text-decoration: underline;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
{
  transform: translateX(-15px);
  opacity: 0;
}
</style>
