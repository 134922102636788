<template>
  <div>
    <Header :info="info" />
    <div class="tContainer">
      <div class="container" style="display:grid;place-items:center">
        <i class="fa fa-minus-circle"></i>
        <h1>HATA</h1>
        <h2>{{ order.error }} :/</h2>
        <p>
          <router-link :to="{ name: 'CompleteOrder' }">
            Tekrar deneme için tıklayınız
          </router-link>
        </p>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.tContainer {
  min-height:calc(100vh - 382px);display:grid;place-items:center
}
i {
  font-size: 100px;
  color: red;
}
h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 20px;
}
p {
  margin-bottom: 8px;
  text-align: center;
}
a {
  color: blue;
  text-decoration: underline;
}
a:hover {
  color: var(--color1);
}
@media screen and (max-width:768px) {
  .tContainer {
    min-height:calc(100vh - 120px);display:grid;place-items:center
  }
}
</style>

<script>
import axios from 'axios'
export default {
  components: { },
  props: ['info'],
  data () {
    return {
      order: ''
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Teşekkürler'),
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  },
  beforeMount () {
    this.getOrder()
  },
  watch: {
    $route (to, from) {
      this.getOrder()
    }
  },
  methods: {
    getOrder: async function () {
      const payload = {
        id: parseInt(this.$route.query.r),
        sId: this.$store.state.user.sId,
        user: this.$store.state.user
      }
      await axios.post('api/getOrder.php', payload).then(response => {
        setTimeout(() => {
          this.order = response.data.order
        }, 0)
      })
    }
  }
}
</script>
