<template>
  <div>
    <div v-if="$store.state.user.userId">
      {{ $store.state.user.userName }}<br>
      {{ $store.state.user.email }}<br><br>
    </div>
    <form v-if="!$store.state.user.userId">
      <h1>Giriş Yapın</h1>
      <p class="formError" v-if="formError">{{ formError }}</p>
      <div id="loginemail" style="margin-top:22px">
        <label>E-Posta Adresiniz</label>
        <input @keypress.enter="login" v-model="email" type="email" autocomplete="email" placeholder="E-Posta Adresiniz">
      </div>
      <div id="loginpass">
        <label>Parolanız</label>
        <input @keypress.enter="login" v-model="pass" type="password" autocomplete="current-password" placeholder="Parolanız">
      </div>
      <div>
        <a @click="setRemember">
          <i :class="[rememberMe ? 'far fa-check-square' : 'far fa-square']"></i> Beni Hatırla
        </a>
      </div>
      <div>
        <router-link :to="{ name: 'Register' }"><i class="fa fa-user"></i> Üye olmak için tıklayın</router-link>
      </div>
      <div style="display:none">
        <router-link :to="{ name: 'ForgetPassword' }"><i class="fa fa-fish"></i> Parolanızı mı unuttunuz?</router-link>
      </div>
      <div>
        <button @click="login" type="button"><i class="fa fa-lock"></i>Giriş Yap</button>
      </div>
    </form>
    <div v-else>
      <form>
        <div>
          <button @click="logout" type="button"><i class="fa fa-lock"></i>Çıkış Yap</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default ({
  props: ['info', 'from'],
  data () {
    return {
      email: '',
      pass: '',
      rememberMe: true,
      user: this.$store.state.user,
      formError: ''
    }
  },
  methods: {
    setRemember: function () {
      this.rememberMe = !this.rememberMe
    },
    login: async function () {
      const payload = {
        email: this.email,
        pass: this.pass,
        user: this.user,
        rememberMe: this.rememberMe
      }
      await axios.post('/api/login.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          if (this.from) {
            this.$router.go(-1)
          } else {
            this.$router.push('/')
          }
        } else {
          this.formError = err
          const field = response.data.field
          const labels = document.querySelectorAll('label')
          labels.forEach(l => {
            l.classList.remove('red')
          })
          if (field) {
            const lbl = document.querySelector('#login' + field + '> label')
            if (lbl) {
              lbl.classList.add('red')
            }
            const inp = document.querySelector('#login' + field + '> input')
            if (inp) {
              inp.focus()
            }
          }
        }
      })
    },
    logout: async function () {
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('/api/logout.php', payload).then(response => {
        this.$store.dispatch('setUser', response.data.user)
      })
    }
  }
})
</script>
