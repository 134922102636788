<template>
  <div class="mb">
    <h1>{{ info.title }}
      <router-link class="seeAll"
      v-if="info.seeAll"
      :to="{ name: 'Products', params: { id: info.seeAll + '-' + $fnc.seo(info.title), top: info.seeAllParent }}">Tümünü Gör</router-link>
    </h1>
    <div :class="listClass">
      <ProductCard v-for="p in info.subs" :key="p.id" :p="p"  />
    </div>
  </div>
</template>

<style scoped>
.seeAll {
  font-size:11px;
  color: #8f8f8f;
  border:1px solid #8f8f8f;
  padding: 3px 8px 3px 8px;
  border-radius: 6px;
  transition: all ease-in 0.2s;
  font-family: var(--font2);
}
.seeAll:hover {
  color: var(--color1);
  border:1px solid var(--color1);
}
h1 {
  font-size: 24px;
  margin-bottom: -4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.list5 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  grid-auto-rows: min-content;
  margin-top: 20px;
}
.list4 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: min-content;
  margin-top: 20px;
}
@media screen and (max-width: 320px) {
  .list5, .list4 {
     grid-template-columns: 1fr;
  }
  .list5 a:last-child{
    display: none;
  }
}
@media screen and (min-width:320px) and (max-width: 480px) {
  .list5, .list4 {
     grid-template-columns: repeat(2, 1fr);
  }

  .list5 a:last-child{
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .list5, .list4 {
     grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width:768px) and (max-width: 1024px) {
  .list5, .list4 {
     grid-template-columns: repeat(3, 1fr);
  }
}
</style>

<script>
import ProductCard from '@/small-components/ProductCard.vue'
export default {
  props: ['info', 'listClass'],
  components: { ProductCard },
  data () {
    return {
      home: 'yes Mofos so'
    }
  }
}
</script>
