<template>
  <div style="min-height:900px">
    <div class="slideContainer">
      <splide :options="home.desktopSlider.options" v-if="home.desktopSlider && width > mobileWidth">
        <splide-slide v-for="item in home.desktopSlider.slides" :key="item.id">
          <div :style="'background:url(' + item.src + ') no-repeat;'" class="slide">
            <router-link :title="item.sUrlText" v-if="item.url" :to="item.url" style="width:100%; height:100%; background:black;display:block;opacity:0"></router-link>
          </div>
        </splide-slide>
      </splide>
      <splide :options="home.mobileSlider.options" v-if="home.mobileSlider && width <= mobileWidth">
        <splide-slide v-for="item in home.mobileSlider.slides" :key="item.id">
          <div :style="'background:url(' + item.src + ');'" class="slide">
            <img :src="item.src" style="display:none">
            <router-link :title="item.sUrlText" v-if="item.url" :to="item.url" style="width:100%; height:100%; background:black;display:block;opacity:0"></router-link>
          </div>
        </splide-slide>
      </splide>
    </div>
    <HomeComponents :info="home.components" />
  </div>
</template>

<script>
import axios from 'axios'
import HomeComponents from '@/home-components/HomeComponents.vue'

export default {
  components: {
    HomeComponents
  },
  data () {
    return {
      home: {},
      mobileWidth: this.$store.state.mobileWidth,
      width: 0
    }
  },
  beforeMount () {
    this.width = this.$fnc.getWidth()
    this.getHome()
  },
  methods: {
    getHome: async function () {
      // const home = this.$store.state.home.desktopSlider
      const home = false
      if (!home) {
        const payload = {
          user: this.$store.state.user
        }
        await axios.post('api/home.php', payload).then(response => {
          this.$store.dispatch('setHome', response.data)
          this.home = this.$store.state.home
        })
      } else {
        this.home = this.$store.state.home
      }
    }
  }
}
</script>

<style scoped lang="scss">
.slide {
  background-position:center !important;
  height:100%;
  background-size:cover !important;
}
h1 {
  font-size: 22px;
}
.blogs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background:#f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.blogList {
  padding: 20px;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  div {
    padding: 0px;
    background:#fff;
    .text {
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 15px;
    }
    img {
      width: 100%;
      height:227px;
    }
    h3 {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.mainProducts {
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
}
.product {
  width: 100%;
  text-align: center;
  h3 {
    font-weight: normal;
    font-size: 18px;
    margin-top: 8px;
  }
  p {
    font-weight: bold;
  }
  img {
    width: 100%;
  }
}
.slider {
  height: 600px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 800px auto;
  div {
    img {width: 100%};
  }
  .sag {
    display: grid;
    gap: 20px;
  }
}
.promo {
  width:100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
}
.textArea {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  h1 {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .slideContainer {
    height:300px;
  }
}
</style>
