<template>
  <div>
    <SimpleHeader :info="info" />
    <div class="container" style="margin-top:20px; min-height:680px">
      <section v-if="!selectedUser">
        <div class="customerArea">
          <div class="sContainer">
            <input
              ref="customerSearch"
              @keypress.enter="searchUsers"
              v-model="search"
              class="search userIcon"
              type="text"
              placeholder="Carilerde Arayın" />
          </div>
          <div class="buttons">
            <button class="button" @click="newCustomer = !newCustomer; users = false">
              <i class="fa fa-user"></i>
              <span>Yeni Cari</span>
            </button>
            <button class="button" @click="newCustomer = !newCustomer; users = false">
              <i class="fa fa-user"></i>
              <span>Perakende Satış</span>
            </button>
          </div>
        </div>
        <div v-if="newCustomer" class="first">
          <p style="margin-bottom:15px; text-align:center">Yeni Cari Ekleyin</p>
          <div autocomplete="nope" method="dialog">
            <div class="row">
              <label>Adı<span class="red">*</span></label>
              <input required autocomplete="off" data-name="name" v-model="newUser.name" type="text" />
            </div>
            <div class="row">
              <label>E-Mail<span class="red">*</span></label>
              <input autocomplete="off" data-name="email" v-model="newUser.email" type="text" />
            </div>
            <div class="row">
              <label>Telefon<span class="red">*</span></label>
              <input v-model="newUser.phone" type="text" />
            </div>
            <div class="row">
              <label>Müşteri Tipi<span class="red">*</span></label>
              <select v-model="userType">
                <option value="personel">Bireysel</option>
                <option value="corp">Kurumsal</option>
              </select>
            </div>
            <div v-if="userType === 'corp'">
              <div class="row">
                <label>Vergi Dairesi<span class="red">*</span></label>
                <input autocomplete="off" v-model="newUser.taxOffice" type="text" />
              </div>
              <div class="row">
                <label>Vergi No<span class="red">*</span></label>
                <input autocomplete="off" v-model="newUser.taxNumber" type="text" />
              </div>
            </div>
            <div v-else>
              <div class="row">
                <label>T.C. No<span class="red">*</span></label>
                <input autocomplete="off" v-model="newUser.tcNumber" type="text" />
              </div>
            </div>
            <div class="row">
              <label>İl<span class="red">*</span></label>
              <select v-model="newUser.city" @change="getDistricts">
                <option value="">Lütfen Seçin</option>
                <option v-for="item in cities" :key="item.id" :value="item">{{ item.name }}</option>
              </select>
            </div>
            <div class="row">
              <label>İlçe<span class="red">*</span></label>
              <select v-model="newUser.district">
                <option value="">Lütfen Seçin</option>
                <option v-for="item in districts" :key="item.id" :value="item">{{ item.name }}</option>
              </select>
            </div>
            <div class="row">
              <label>Adres<span class="red">*</span></label>
              <input autocomplete="off" v-model="newUser.address" type="text" />
            </div>
          </div>
          <div class="row">
            <label></label>
            <button style="width:100%" class="button" @click="saveNewCustomer">
              <i v-if="!isSaving" class="fa fa-save"></i>
              <i v-else class="fa fa-spinner spin"></i>
              <span>Cariyi Kaydet</span>
            </button>
          </div>
        </div>
      </section>
      <section>
        <div v-if="!selectedUser">
          <div class="users" v-if="users">
            <div class="user" v-for="user in users" :key="user.id" @click="selectUser(user)">
              <div class="userImg">
                <div class="oval">{{ user.kullanicilarAdi.substr(0, 1) }}</div>
              </div>
              <div class="userInfo">
                <div>{{ user.kullanicilarAdi }}</div>
                <div>{{ user.kullanicilarEmail }}</div>
                <div class="small">{{ user.hia_kullanicilarGrupAdi }}</div>
                <div class="small">CR{{ user.kullanicilarId }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="selectedUser">
            <div class="userImg">
              <div class="oval orange">{{ selectedUser.kullanicilarAdi.substr(0, 1) }}</div>
            </div>
            <div class="selectedUserContainer">
              <div class="userInfo">
                <div>{{ selectedUser.kullanicilarAdi }}</div>
                <div>{{ selectedUser.kullanicilarEmail }}</div>
                <div class="small">{{ selectedUser.hia_kullanicilarGrupAdi }}</div>
                <div class="small">CR{{ selectedUser.kullanicilarId }}</div>
              </div>
              <div>
                <div class="button" @click="selectUser(false)">
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="selectedUser">
        <div class="sContainer">
          <input
            ref="productSearcher"
            v-model="productSearch"
            @keypress.enter="searchProducts"
            class="search barcodeIcon"
            style="max-width:100%"
            type="text"
            placeholder="Ürünlerde Arayın veya Barkod Okutun" />
        </div>
        <div v-if="products" class="products">
          <div v-for="product in products" :key="product.pid" class="product" @click="productDetails(product.pid)">
            <div class="productImg">
              <img v-if="product.image" :src="cdn + '64_' + product.image" />
            </div>
            <div class="productInfo">
              <div>{{ product.name }}</div>
              <div class="small">{{ product.cat_name }}</div>
              <div class="small" v-if="product.barcode">{{ product.barcode }}</div>
            </div>
          </div>
        </div>
      </section>
      <section style="margin-top:20px; margin-bottom: 20px">
        <ProductDetail :inner="true" :innerPid="selectedPid" v-if="selectedPid" />
      </section>
      <section v-if="orderProducts.length > 0">
        <div class="orderItem" style="font-size:0.8rem; background:#ccc;padding-top:8px; padding-bottom:8px;border-radius:6px">
          <div></div>
          <div>Ürün</div>
          <div class="center">Miktar</div>
          <div class="right desktop">KDV</div>
          <div class="right desktop">Br. Fiyat</div>
          <div class="right">Toplam</div>
        </div>
        <div v-for="item in orderProducts" :key="item.id" class="orderItem">
          <div class="image"><img :src="cdn + '64_' + item.product.images[0]" v-if="item.product.images" /></div>
          <div>
            <p>{{ item.product.title }}</p>
            <div v-if="item.productType === 'printProduct'">
              <p style="font-size:12px; font-weight:bold;margin-top:4px; margin-bottom:4px">{{ item.printTechnicName.technicName }}</p>
              <p v-for="s in item.selecteds" :key="s.id" style="font-size:12px; display:grid;grid-template-columns:60px auto">
                <span>{{ s.tipName }}</span>
                <span>: {{ s.name }}</span>
              </p>
            </div>
            </div>
          <div class="center">{{ item.qty }}</div>
          <div class="right desktop">%{{ item.kdvOran }}</div>
          <div class="right desktop">{{ item.unitPrice.toFixed(2) }} TL</div>
          <div class="right">{{ item.totalPrice.toFixed(2) }} TL</div>
          <div>
            <button class="button" style="border:none" @click="removeFromOrderProducts(item)">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div class="flex justify-right mb15">
          <div>
            <div class="flex justify-right">
              <span>Ara Toplam</span>
              <span>: {{ parseFloat(matrahTotal).toFixed(2) }} TL</span>
            </div>
            <div class="flex justify-right">
              <span>Toplam</span>
              <span>: {{ parseFloat(grandTotal).toFixed(2) }} TL</span>
            </div>
          </div>
        </div>
        <div class="flex justify-right" style="padding-bottom:40px">
          <button class="button" @click="saveOrder(-1)">
            <i v-if="isSavingOrder" class="fa fa-spinner spin"></i>
            <i v-else class="fa fa-check"></i>
            <span v-if="isSavingOrder">Lütfen Bekleyin</span>
            <span v-else>Teklif Olarak Kaydet</span>
          </button>
          <button class="button green" @click="saveOrder(1)">
            <i v-if="isSavingOrder" class="fa fa-spinner spin"></i>
            <i v-else class="fa fa-check"></i>
            <span v-if="isSavingOrder">Lütfen Bekleyin</span>
            <span v-else>Sipariş Olarak Kaydet</span>
          </button>
        </div>
      </section>
    </div>
    <Footer :info="info" :noNavBar="true" />
  </div>
</template>

<script>
import axios from 'axios'
import ProductDetail from '@/components/ProductDetail.vue'

export default {
  components: { ProductDetail },
  props: ['info'],
  data () {
    return {
      id: null,
      parent: null,
      pageType: null,
      cdn: this.$store.state.mediaCdn,
      page: {},
      otherPages: [],
      images: [],
      search: '',
      users: false,
      selectedUser: false,
      productSearch: '',
      products: false,
      selectedPid: false,
      orderProducts: [],
      grandTotal: 0,
      matrahTotal: 0,
      isSavingOrder: false,
      newCustomer: false,
      cities: [],
      districts: [],
      userType: 'personel',
      hide: false,
      isSaving: false,
      newUser: {
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        district: '',
        taxOffice: '',
        taxNumber: '',
        tcNumber: ''
      }
    }
  },
  mounted () {
    this.getCities()
    this.$refs.customerSearch.focus()
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.parent = this.$route.params.parent
      this.pageType = this.$route.name
      this.page = null
      this.getPage()
    },
    orderProducts (val) {
      this.grandTotal = 0
      this.matrahTotal = 0
      val.forEach(item => {
        this.grandTotal += item.totalPrice
        if (item.productType === 'printProduct') {
          this.matrahTotal += item.unitPrice
        } else {
          this.matrahTotal += item.unitPrice * item.qty
        }
      })
    }
  },
  methods: {
    async saveOrder (orderType) {
      if (this.isSavingOrder === true) {
        return
      }
      this.isSavingOrder = true
      const payload = {
        customer: this.selectedUser,
        products: this.orderProducts,
        orderType: orderType,
        total: this.matrahTotal,
        kdv: this.grandTotal - this.matrahTotal,
        user: this.$store.state.user
      }
      await this.axios.post('/api/newOrder.php', payload).then(response => {
        // this.isSavingOrder = false
        // refresh page
        this.$router.go()
      })
      console.log(payload)
    },
    saveNewCustomer () {
      this.isSaving = true
      const reqFeilds = ['name', 'email', 'phone', 'address', 'city', 'district']
      if (this.userType === 'personel') {
        reqFeilds.push('tcNumber')
      } else {
        reqFeilds.push('taxNumber')
        reqFeilds.push('taxOffice')
      }
      let error = false
      reqFeilds.forEach(field => {
        if (!this.newUser[field]) {
          error = true
        }
      })
      if (error) {
        alert('Lütfen tüm alanları doldurunuz.')
        this.isSaving = false
        return
      }
      const payload = {
        name: this.newUser.name,
        email: this.newUser.email,
        phone: this.newUser.phone,
        address: this.newUser.address,
        city: this.newUser.city.name,
        district: this.newUser.district.name,
        userType: this.userType
      }
      if (this.userType === 'personel') {
        payload.tcNumber = this.newUser.tcNumber
      } else {
        payload.taxNumber = this.newUser.taxNumber
        payload.taxOffice = this.newUser.taxOffice
      }
      this.axios.post('api/newCustomer.php', payload).then(response => {
        const err = response.data.error
        if (err === 'none') {
          this.search = this.newUser.email
          this.searchUsers(true)
        } else {
          alert(err)
        }
      }).finally(() => {
        this.isSaving = false
      })
    },
    getCities () {
      this.newUser.city = ''
      this.newUser.district = ''
      this.axios.get('api/getCities.php').then(res => {
        this.cities = res.data.data
      })
    },
    getDistricts () {
      this.districts = []
      this.newUser.district = ''
      const payload = {
        city: this.newUser.city.name
      }
      this.axios.post('api/getDistricts.php', payload).then(res => {
        this.districts = res.data.data
      })
    },
    showNewCustomer () {
      this.newCustomer = true
    },
    removeFromOrderProducts (item) {
      this.orderProducts = this.orderProducts.filter(i => i.id !== item.id)
    },
    productDetails: function (pid) {
      console.log(pid)
      this.selectedPid = false
      this.$nextTick(() => {
        this.selectedPid = pid
        this.products = false
      })
    },
    selectUser: function (user) {
      this.selectedUser = user
      setTimeout(() => {
        const productSearcher = this.$refs.productSearcher
        if (productSearcher) {
          productSearcher.focus()
        }
      }, 40)
      if (!user) {
        setTimeout(() => {
          const customerSearch = this.$refs.customerSearch
          if (customerSearch) {
            customerSearch.focus()
          }
        }, 40)
      }
    },
    searchUsers: async function (selectFirst = false) {
      this.newCustomer = false
      this.selectedPid = false
      if (this.search === '') {
        this.users = false
        return
      }
      this.selectedUser = false
      const payload = {
        search: this.search,
        user: this.$store.state.user
      }
      await this.axios.post('api/searchUsers.php', payload).then(response => {
        if (response.data.error !== 'error') {
          this.users = response.data.users
          if (selectFirst === true) {
            this.selectUser(this.users[0])
          }
        } else {
          this.users = false
        }
      })
    },
    searchProducts: async function () {
      this.selectedPid = false
      if (this.productSearch === '') {
        this.products = false
        return
      }
      const payload = {
        search: this.productSearch,
        user: this.$store.state.user
      }
      await this.axios.post('api/searchProducts.php', payload).then(response => {
        this.products = response.data.products
      })
    },
    getPage: async function () {
      const payload = {
        id: parseInt(this.$route.params.id),
        parent: this.$route.params.parent,
        sId: this.$store.state.user.sId,
        pageType: this.pageType,
        userId: this.$store.state.user.userId
      }
      await axios.post('api/getPage.php', payload).then(response => {
        setTimeout(() => {
          this.page = response.data.page
          this.images = response.data.images
          this.otherPages = response.data.otherPages
        }, 0)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.first {
  max-width:340px;border-radius:8px; margin:auto;margin-top:20px;padding:12px; border:1px solid #ccc;
}
.spin {
  animation: spin 1s linear infinite;
}
.flex {
  display: flex;
  gap: 14px;
}
.justify-right {
  justify-content: flex-end;
}
.mb15 {
  margin-bottom: 15px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.red {
  color:red;
  margin-left:4px;
}
.customerArea {
  display:grid;
  grid-template-columns: 340px 300px;
  justify-items:center;
  width:620px;
  gap:16px;
  margin: auto;
  .sContainer {
    width: 100%;
  }
  .buttons {
    width: 100%;
    display: flex;
    gap:12px;
  }
}
.row {
  display:flex; flex-direction:row; gap:12px; justify-content:center;width:100%;
  margin-bottom: 16px;
  label {
    width:132px;
    text-align: right;
    font-size: 12px;
    color: #5a5a5a;
  }
  input, select {
    width:100%;
    padding:8px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  align-items: center;
}
.sContainer {
  display: grid;
  place-items: center;
}
.search {
  width: 100%;
  max-width: 620px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 16px;
  color: #333;
  outline: none;
}

.users {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px;
}
.user {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  // transition: all 0.3s ease;
  &:hover {
    background: #f5f5f5;
  }
}
.userImg {
  display:grid;
  place-items: center;
}
.oval {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ccc;
  display: grid;
  place-items: center;
  font-weight: bold;
}
.selectedUser {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.button {
  background: #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #a9a9a9;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 8px;
  &:hover {
    background: #858585;
    color: white;
  }
}
.green {
  background: #118211;
  color: white;
  &:hover {
    background: #086308
  }
}
.selectedUserContainer {
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
}
.orange {
  background: #f5a623;
  color: white;
}

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.product {
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-gap: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  // transition: all 0.3s ease;
  &:hover {
    background: #f5f5f5;
  }
}
.productInfo {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}
.productImg {
  display:grid;
  place-items: center;
  width: 64px;
  height: 64px;
  border: 1px solid #ccc;
  padding: 4px;
  place-items: center;
  overflow: hidden;
  background:white;
  border-radius: 3px;
  img {
    width: 100%;
  }
}
.small {
  font-size: 0.85rem;
  font-weight: bold;
}
.userIcon {
  padding-left: 32px;
  background: url('../assets/search.svg') no-repeat;
  background-size: 16px;
  background-position: 10px center;
}
.barcodeIcon {
  padding-left: 36px;
  background: url('../assets/barcode.svg') no-repeat;
  background-size: 16px;
  background-position: 13px center;
}
.footerContainer{
  background:#222529;
  padding-top: 30px;
  padding-bottom: 30px;
  color:white;
  height: 134px;
}
.orderItem {
  display: grid;
  grid-template-columns: 72px auto 54px 40px 120px 140px 31px;
  grid-gap: 6px;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  .image {
    width: 64px;
    height: 64px;
    border: 1px solid #ccc;
    padding: 4px;
    place-items: center;
    overflow: hidden;
    background:white;
    border-radius: 3px;
    img {
      width: 100%;
    }
  }
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
@media screen and (max-width:768px) {
  .customerArea {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 12px;
    .buttons {
      gap: 12px;
      justify-content: center;
    }
  }
  .orderItem {
    grid-template-columns: 42px auto 30px 70px 31px;
    font-size: 0.67rem;
    .image {
      width: 40px;
      height: 40px;
    }
  }
  .desktop {
    display: none;
  }
}
</style>
