<template>
  <div>
    <h1 class="productTitle" v-html="product.title"></h1>
    <p style="margin-bottom:12px;font-size:13px;margin-top:-12px" v-if="product.subTitle">{{ product.subTitle }}</p>
    <div v-if="rating.average > 0" style="display:flex; justify-:left;align-items:center; margin-bottom:12px;margin-top:0px">
      <div style="font-weight:bold;height:24px;margin-right:12px;">
        {{ rating.average }}
      </div>
      <star-rating v-if="rating.average > 0" :round-start-rating="false" v-model="rating.average" :star-size="16" :show-rating="false" :read-only="true"></star-rating>
      <b style="font-size:11px;margin-left:13px;color:#666">{{ rating.rateCount }} değerlendirme</b>
    </div>
    <div v-if="!isLoading" class="productDetail">
      <div>
        <div v-if="product.banners.length > 0">
          <div v-for="item in product.banners" :key="item.source">
            <router-link :to="item.title" v-if="item.title !== 'banner'"><img :src="mediaCdn + '1200_' + item.source" width="100%"></router-link>
            <img v-else :src="mediaCdn + '1200_' + item.source" width="100%">
          </div>
        </div>
        <p v-if="product.description" v-html="product.description"></p>
        <div v-if="product.menuInfo" style="font-size: 12px;">
           {{ product.menuInfo.body }}
        </div>
        <div v-if="selectedVariant">
          <div class="adets">
            <b>{{ product.variantGroup }} Seçin</b>
            <div class="options">
              <div @click="selectVariant(item)" :class="{ 'active': item.id === selectedVariant.id, 'notInStock': item.stock < 1 }" v-for="item in product.variants" :key="item.id">
                {{ item.title }}
                <br><span v-if="item.stock !== 99999" style="color:orangered;font-size:10px">Stok: {{ item.stock }}</span>
              </div>
            </div>
          </div>
        </div>
        <BuyTogether v-if="product.isWallpaper === 0 && max > 0" :p="product.bundles" />
        <div class="adets" v-if="max > 0 && product.isWallpaper === 0">
          <h2 style="text-align: center;font-weight: bold;">{{ termin }} İş Gününde Kargoda</h2>
          <p style="text-align:center;" v-if="!selectedVariant">Stok: {{ max }}</p>
          <div class="adetRow" v-for="adet in 1" :key="adet">
            <div class="rakamci activeButton">
              <div class="kdvHaric">{{ (price * adet).toFixed(2) }} TL <span> + KDV</span></div>
              <div class="kdvDahil">{{ (price * (1 + (product.kdvOran / 100)) * adet).toFixed(2) }} TL (KDV Dahil)</div>
            </div>
          </div>
          <div class="qtyRow">
            <span>Miktar</span>
            <div class="qtySelector">
              <button @click="decrease"><i class="fa fa-minus"></i></button>
              <input @change="validate" v-model.number="qty" min="1" :max="max" type="tel">
              <button @click="increase"><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div style="font-size: 12px;margin-top: 10px;">
            Belirtilen kargo tarihleri, iş gününde (haftasonu ve resmi tatiller hariç), ekspres gönderim seçeneği için aynı gün saat 10:00'a kadar, diğer gönderim seçenekleri için ise aynı gün saat 14:00'a kadar tarafınızdan baskı onayı verilmesi halinde geçerli olacak tahmini tarihlerdir.
          </div>
        </div>
        <div v-else>
          <div v-if="product.isWallpaper === 0">
            Bu ürün şu anda temin edilememektedir
          </div>
          <div v-else>
            <div class="adets">
              <div class="adetRow" style="padding:0;margin-bottom:20px">
                <div class="activeButtons" style="cursor:default; display:grid;grid-template-columns: 1fr;gap:20px">
                  <div class="sizeRow">
                    <label for="en"><b style="margin-right:8px">En</b>(Cm cinsinden girin) <span style="margin-left:5px;color:red" v-if="product.enMax"> En fazla <b>{{ product.enMax }}cm</b> girebilirsiniz</span></label>
                    <input min="0" @blur="fixMax" :max="product.enMax" autocomplete="off" id="en" v-model="en" type="number" placeholder="Örneğin: 2.5 metre için 250">
                  </div>
                  <div class="sizeRow">
                    <label for="boy"><b style="margin-right:8px">Boy</b> (Cm cinsinden girin) <span style="margin-left:5px;color:red" v-if="product.boyMax"> En fazla <b>{{ product.boyMax }}cm</b> girebilirsiniz</span></label>
                    <input min="0" @blur="fixMax" :max="product.boyMax" autocomplete="off" id="boy" v-model="boy" type="number" placeholder="Örneğin: 4 metre için 400">
                  </div>
                </div>
              </div>
              <div class="selections">
                <label style="font-size:12px; display:flex;width:100%;margin-bottom:6px;font-weight:bold">Baskı Materyali</label>
                <div class="buttonset" style="display:grid;">
                  <button class="activeButton" v-for="item in product.wallpapers" :key="item.id" @click="selectWallpaper(item)">
                    <div>
                      <div class="t" style="font-size:14px;font-weight:bold;margin-bottom:6px">{{ item.title }} Metrekare Fiyatı</div>
                      <div class="kdvHaric">{{ (item.price).toFixed(2) }} TL <span> + KDV</span></div>
                      <div class="kdvDahil">{{ ((item.price * (1 + (product.kdvOran / 100)))).toFixed(2) }} TL (KDV Dahil)</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <BuyTogether :p="product.bundles" />
          </div>
        </div>
      </div>
      <div>
        <ProductPriceNormal :totalPrice="totalPrice" :vat="product.kdvOran" :isSaving="isSaving" :max="product.avl" />
      </div>
      <FileContainer v-if="productType === 'printProduct' && $fnc.getWidth() < $store.state.mobileWidth" :title="product.title" :sizeName="sizeName" :fileGroups="fileGroups" :fileGroup="fileGroup" :fileGroupIndex="fileGroupIndex" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ProductPriceNormal from '@/small-components/ProductPriceNormal.vue'
import FileContainer from '@/small-components/FileContainer.vue'
import BuyTogether from '@/small-components/BuyTogether.vue'

export default {
  props: ['product', 'printTechnics', 'rating'],
  components: { ProductPriceNormal, FileContainer, BuyTogether },
  data: function () {
    return {
      pid: this.$route.params.pid,
      bid: 0,
      en: '',
      boy: '',
      selectedWallpaper: false,
      isLoading: false,
      isSaving: false,
      productType: '',
      mediaCdn: this.$store.state.mediaCdn,
      variants: [],
      variantGroup: null,
      printColors: null,
      printKnives: null,
      printFolds: null,
      printDirections: null,
      printLaks: null,
      printSizes: null,
      selectedTechnic: null,
      selectedVariant: null,
      selectedColor: null,
      selectedDirection: null,
      selectedKnive: null,
      selectedFold: null,
      selectedLak: null,
      selectedSize: null,
      selectedAdet: 0,
      fileGroups: [],
      fileGroup: [],
      sizeName: '',
      fileGroupIndex: 0,
      adets: null,
      newAdets: null,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: 1,
      price: 0,
      totalPrice: 0,
      qty: 1,
      max: 0,
      selectedBundle: 0,
      bundlePrice: 0,
      bundleVat: 0
    }
  },
  mounted () {
    // this.calculate()
    this.first()
  },
  watch: {
    $route (to, from) {
      this.isLoading = true
      this.qty = 1
      setTimeout(() => {
        this.calculate()
      }, 100)
    },
    newPid (to, from) {
      setTimeout(() => {
        this.first()
      }, 40)
    },
    metrekare (to, from) {
      this.calcM2()
    }
  },
  computed: {
    metrekare: function () {
      const m2 = (parseInt(this.en) * parseInt(this.boy)) / 10000
      if ((m2 < 1) || (!m2)) {
        return 1
      } else {
        return m2
      }
    },
    newPid: function () {
      return this.$parent.product.id
    }
  },
  methods: {
    fixMax: function () {
      const enMax = this.product.enMax
      const boyMax = this.product.boyMax
      if (enMax > 0) {
        if (this.en > enMax) {
          this.en = enMax
          this.$toast.error('Kağıt eni en fazla ' + enMax + 'cm olabilir')
        }
        if (this.boy > boyMax) {
          this.boy = boyMax
          this.$toast.error('Kağıt boyu en fazla ' + boyMax + 'cm olabilir')
        }
      }
    },
    selectWallpaper: function (item) {
      this.selectedWallpaper = item
      this.calcM2()
    },
    calcM2: function () {
      if (!this.selectedWallpaper) {
        this.selectedWallpaper = this.product.wallpapers[0]
      }
      const productPrice = this.selectedWallpaper.price
      const m2 = this.metrekare * productPrice
      let m2price = 0
      if (m2 >= productPrice) {
        m2price = this.metrekare * productPrice
      } else {
        m2price = productPrice
      }
      this.totalPrice = ((m2price * (1 + (this.product.kdvOran / 100))) + (this.bundlePrice * (1 + (this.bundleVat / 100))))
    },
    first: function () {
      const variants = this.product.variants
      if (variants) {
        const found = variants.find(element => element.stock > 0)
        if (found) {
          this.selectVariant(found)
        }
      } else {
        this.price = this.product.price
        this.max = this.product.avl
        this.calculate()
      }
      this.termin = this.product.termin
      const bundles = this.product.bundles
      if (bundles) {
        this.selectBundle(bundles.p[0])
      }
      if (this.product.isWallpaper === 1) {
        this.calcM2()
      }
      this.isLoading = false
    },
    validate: function () {
      if (this.max === 0) {
        this.$toast.warning('Bu ürün şu anda tedarik edilememektedir')
        return
      }
      if (this.qty < 1) {
        this.qty = 1
        this.$toast.warning('En az 1 adet ekleyebilirsiniz')
      }
      if (this.qty > this.max) {
        this.qty = this.max
        this.$toast.warning('En fazla ' + this.max + ' adet ekleyebilirsiniz')
      }
      if (!Number.isInteger(this.qty)) {
        // this.$toast.warning('notInteger')
        this.qty = 1
      }
      this.calculate()
    },
    increase: function () {
      if (this.qty < this.max) {
        this.qty = this.qty + 1
        this.calculate()
      }
    },
    decrease: function () {
      if (this.qty > 1) {
        this.qty = this.qty - 1
        this.calculate()
      }
    },
    calculate: function () {
      this.totalPrice = (this.price + this.bundlePrice) * (1 + (this.product.kdvOran / 100)) * this.qty
    },
    addToCart: async function (qty) {
      console.clear()
      console.log(this.product.isWallpaper)
      let wpOptions = false
      if (this.product.isWallpaper === 1) {
        if (!this.en) {
          alert('En girmelisiniz')
          return
        }
        if (!this.boy) {
          alert('Boy girmelisiniz')
          return
        }
        wpOptions = {
          en: this.en,
          boy: this.boy,
          wpId: this.selectedWallpaper.id
        }
      }
      this.isSaving = true
      let pid = this.pid
      if (this.$parent.inner === true) {
        pid = this.$parent.innerPid
      }
      const payload = {
        pid: parseInt(pid),
        bid: parseInt(this.bid),
        qty: parseInt(this.qty),
        productType: this.productType,
        user: this.$store.state.user,
        bundle: this.selectedBundle,
        wpOptions: wpOptions,
        unitPrice: this.price,
        totalPrice: this.totalPrice,
        kdvOran: this.product.kdvOran,
        id: Math.floor(Math.random() * 1000000000),
        product: this.product
      }
      if (this.$parent.inner) {
        this.$parent.$parent.orderProducts.push(payload)
        this.$parent.$parent.selectedPid = false
        this.$parent.$parent.productSearch = ''
        setTimeout(() => {
          this.isSaving = false
        }, 100)
        return
      }
      await axios.post('api/addToCart.php', payload).then(response => {
        this.$store.dispatch('setCart', response.data.cartCount)
      })
      this.isSaving = false
    },
    selectBundle: function (bundle) {
      this.selectedBundle = bundle
      this.bundlePrice = bundle.price
      this.bundleVat = bundle.vatRate
      if (this.product.isWallpaper === 1) {
        this.calcM2()
      } else {
        this.calculate()
      }
    },
    selectVariant: function (variant) {
      if (variant.stock < 1) {
        return
      }
      if (this.selectedVariant !== variant) {
        this.qty = 1
      }
      this.selectedVariant = variant
      this.stockInCart = this.selectedVariant.stockInCart
      this.price = variant.price + this.bundlePrice
      this.max = variant.stock
      this.bid = variant.id
      if (this.selectedVariant.images.length > 0) {
        this.$parent.sp(this.selectedVariant.images)
      } else {
        this.$parent.product.images = this.product.fullImages
      }
      if (this.product.isWallpaper === 1) {
        this.calcM2()
      } else {
        this.calculate()
      }
    }
  }
}
</script>

<style lang="scss">
.sizeRow {
  width: 100%;
  label {
    text-align: left;
    width:100% !important;
    display:flex;
    font-size: 12px;
    margin-bottom: 6px;
  }
  input {
    width:100%;
    padding:12px;
    border:2px solid #40bbf4;
    border-radius: 5px;
  }
}
.qtyRow {
  display:flex;justify-content: flex-end; align-items:center;
  gap:12px;
  .qtySelector {
    display: grid;
    grid-template-columns:28px 80px 28px;
    height: 30px;
    // border: 1px solid #ccc;
    background: #ccc;
    border-radius: 6px;
    overflow: hidden;
    align-items: center;
    button {
      display: grid;
      place-items: center;
      height: 100%;
      border: none;
      width: 100%;
      background: #dadada;
      i {
        color:#666;
      }
    }
    button:hover {
      background: var(--color1);
      i {
        color:#fff;
      }
    }
    input {
      border:none;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      height: 100%;
      text-align:center;
    }
    input:focus {
      box-shadow: unset;
    }
  }
}
.productDetail {
  display:grid;
  grid-template-columns:auto 200px;
  height: 100%;
  gap:20px
}
.productTitle {
  font-weight: bold;
  color:var(--black1);
  text-align: left;
  font-size:1.3rem;
  font-display: swap;
  padding-bottom: 10px;
  user-select: text;
}
.options {
  display: grid;
  grid-template-columns:  repeat(3, 1fr);
  gap:12px;
  margin-top: 8px;
  div {
    user-select: none;
    border:2px solid #ccc;
    display: grid;
    place-items: center;
    gap: 0px;
    font-weight: bold;
    margin:0px;
    padding:4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    cursor: pointer;
    text-align:center;
  }
  div.active {
    border: 2px solid var(--color2);
    color: var(--color2);
  }
  div.notInStock {
    border-color: #bebebe;
    color: #bebebe;
    text-decoration: line-through;
    cursor: not-allowed;
  }
}
@media screen and (max-width:768px) {
  .qtyRow {
    justify-content:center;
  }
  .productDetail {
    grid-template-columns: 1fr;
    height: unset;
  }
}
.selections {
  display:grid;
  grid-template-columns: 1fr;
  place-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  div {
    width: 100%;
    font-size: 11px;
    font-weight: bold;
  }
  .buttonset {
    display:flex;
    align-content: center;
    gap:10px;
  }
  .title {
    display: grid;
    grid-template-columns:1fr;
    align-items: center;
    color: #555;
  }
  .title i {
    display:none;
  }
}
.buttonset button {
  width: 100%;
  border:2px dashed #ccc;
  color: grey;
  background: #fff;
  border-radius: 4px;
  font-size: 11px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0px;
  outline: none;
  display: grid;
  gap: 2px;
  place-items: center;
  box-shadow:0px 4px 12px -8px rgba(0, 0, 0, 0.4) !important;
}
.buttonset button i {
  font-size: 26px;
  margin-top: 4px;
}
.buttonset button:hover {
  border: 2px solid rgb(136, 136, 136);
  color: black;
}
.buttonset button:focus, .buttonset button:active, .activeButton {
  border: 2px solid #40bbf4 !important;
  color: black !important;
}
.adets {
  background:#fafafa;
  padding: 15px;
  font-size: 12px;
  margin-top: 10px;
  border-radius: 6px;
}
.adetRow {
  display: grid;
  grid-template-columns: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.adetci {
  display: grid;
  place-items: center;
  gap: 0px;
  font-size: 10px !important;
  font-weight: bold;
  margin:0px;
  padding:0px;
}
.rakamci {
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #d4d4d4;
  cursor: pointer;
  transition: all 0.3s;
}
.rakamci .kdvHaric {
  font-size: 20px !important;
  font-weight: bold;
  line-height:20px;
}
.rakamci .kdvDahil{
  font-size: 11px !important;
  font-weight: bold;
  line-height:11px;
}
.rakamci:hover {
  border: 2px solid #979797;
}
.rakam {
  font-weight: bold;
  font-size: 13px;
  line-height: 13px !important;
}
.adet {
  font-size: 11px;
  line-height: 11px !important;
}
</style>
